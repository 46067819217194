import { setSessionExpired, useMainController } from "../../../../context";
import http from "../../../../services/http-common";
import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { useTheme } from "@mui/material";

export function PromptEditor() {
  const theme = useTheme();
  const [dispatch] = useMainController();
  const [prompt, setPrompt] = useState("");

  const onChangePrompt = (html) => {
    setPrompt(html);
  };

  const getPrompt = useMutation(
    () => {
      return http.get("chat/prompt", null, setSessionExpired, dispatch);
    },
    {
      onSuccess: ({ data }) => {
        setPrompt(data);
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  useEffect(() => {
    getPrompt.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePrompt = useMutation(
    async () => {
      return await http.put("chat/prompt", { prompt: prompt });
    },
    {
      onSuccess: () => {
        toast.success("Successfully Saved!");
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  const onSavePrompt = () => {
    savePrompt.mutate();
  };
  return (
    <div className="flex w-full justify-center">
      <div className="mt-5 flex w-full max-w-[800px] flex-col gap-8">
        <ReactQuill
          theme="snow"
          className={`mb-3 max-h-[300px] ${
            theme.palette.mode === "dark" && "!text-white"
          } `}
          value={prompt}
          onChange={onChangePrompt}
        />
        <div className="flex justify-center mt-2">
          <Button
            onClick={onSavePrompt}
            className="min-w-[150px]"
            color={theme.palette.mode === "dark" && "white"}
          >
            save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PromptEditor;
