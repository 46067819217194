import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

import Routes from "./Routes";
import store from "./store";
import { createTheme } from "./theme";

import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useMemo, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { SmartBanner } from "./utils/SmartBanner/SmartBanner";
import ReactGA from "react-ga4";
import { ColorContext } from "./theme/ColorContext";
import { PaletteMode, useMediaQuery } from "@mui/material";

const TRACKING_ID = "G-JX56LKXR0H";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [mode, setMode] = useState<PaletteMode>("light");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: (themeMode: any) => {
        console.log(themeMode);
        if (themeMode === "system") {
          setMode(prefersDarkMode ? "dark" : "light");
        } else {
          setMode(themeMode);
        }
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        direction: "ltr",
        responsiveFontSizes: true,
        mode: mode,
      }),
    [mode]
  );

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnMount: false,
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
        },
      },
    })
  );

  return (
    <Provider store={store}>
      <ColorContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <GoogleOAuthProvider clientId="392129545115-gujqk1tj92oer5in52ofk2n8ul6jn8vb.apps.googleusercontent.com">
            <CssBaseline />
            <QueryClientProvider client={queryClient.current}>
              <SmartBanner />
              <Router>
                <Routes />
              </Router>
            </QueryClientProvider>
            <ToastContainer />
          </GoogleOAuthProvider>
        </ThemeProvider>
      </ColorContext.Provider>
    </Provider>
  );
}

export default App;
