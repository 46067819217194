import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import { useMutation } from "react-query";
import http from "../../services/http-common";
import { toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

function EditChatFooter({ id = "" }) {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const getFooterContent = useMutation(
    () => {
      return http.get("user/footer-note");
    },
    {
      onSuccess: ({ data }) => {
        setContent(data?.footerNote);
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  useEffect(() => {
    getFooterContent.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const saveFooter = useMutation(
    async () => {
      return await http.put("user/footer-note", { content: content });
    },
    {
      onSuccess: () => {
        toast.success("Successfully Saved!");
        handleOpen();
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  const onChangePrompt = (html) => {
    setContent(html);
  };

  const onUpdateFooter = () => {
    saveFooter.mutate();
  };

  return (
    <>
      <div className="flex flex-row justify-center items-center">
        <Typography
          variant="body2"
          sx={{
            width: "100%",
            textAlign: "center",
            display: { xs: "block", lg: "none" },
          }}
        >
          {parse(content)}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#8F92A1",
            textAlign: "center",
            display: { xs: "none", lg: "block" },
          }}
        >
          {parse(content)}
        </Typography>
        {user?.role === "admin" && (
          <Tooltip content="Edit">
            <IconButton variant="text" onClick={handleOpen}>
              <FaRegEdit
                color={theme.palette.mode === "dark" && "white"}
                className="h-5 w-5"
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Dialog
        open={open}
        handler={handleOpen}
        className={`h-140 ${theme.palette.mode === "dark" && "!bg-[#282d3d]"}`}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody
          divider
          className={`flex-col max-h-full  ${
            theme.palette.mode === "dark" && "!bg-[#282d3d] border-y-[#363b4e] "
          }`}
        >
          <div
            className={`w-full flex justify-center gap-2 items-center ${
              theme.palette.mode === "dark" && "text-[#e3e3e3]"
            } `}
          >
            Update Chat Footer
          </div>
          <div className="flex w-full justify-center">
            <div className="mt-5 flex w-full max-w-[800px] flex-col gap-8">
              <ReactQuill
                theme="snow"
                className={`mb-3 max-h-[300px] ${
                  theme.palette.mode === "dark" && "!text-white"
                } `}
                value={content}
                onChange={onChangePrompt}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
          <Button
            variant="gradient"
            onClick={onUpdateFooter}
            disabled={loading}
            color={theme.palette.mode === "dark" ? "white" : "green"}
          >
            <span>Update</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default EditChatFooter;
