import { setSessionExpired, useMainController } from "../../../../context";
import http from "../../../../services/http-common";
import { Button, Textarea } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material";

export function GreetingMessageEditor(props) {
  const theme = useTheme();

  const [dispatch] = useMainController();
  const [greeting, setGreeting] = useState("");

  const onChangeGreeting = (e) => {
    setGreeting(e.target.value);
  };

  const getGreetingMessage = useMutation(
    () => {
      return http.get("chat/greeting", null, setSessionExpired, dispatch);
    },
    {
      onSuccess: ({ data }) => {
        setGreeting(data);
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  useEffect(() => {
    getGreetingMessage.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveGreeting = useMutation(
    async () => {
      return await http.put("chat/greeting", { greeting: greeting });
    },
    {
      onSuccess: () => {
        toast.success("Successfully Saved!");
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  const onSaveGreeting = () => {
    saveGreeting.mutate();
  };
  return (
    <div className="flex w-full justify-center">
      <div className="mt-5 flex w-full max-w-[800px] flex-col gap-4">
        <Textarea
          value={greeting}
          onChange={onChangeGreeting}
          label="Prompt for Chat"
          resize
          className={`min-h-[300px] !border !border-gray-300 ${
            theme.palette.mode === "dark"
              ? "text-white"
              : " focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
          } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
          labelProps={{
            className: theme.palette.mode === "dark" && "!text-white ",
          }}
        />
        <div className="flex justify-center">
          <Button
            onClick={onSaveGreeting}
            className="min-w-[150px]"
            color={theme.palette.mode === "dark" && "white"}
          >
            save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default GreetingMessageEditor;
