import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import { BsThreeDots } from "react-icons/bs";

import CloseIcon from "@mui/icons-material/Close";
import MainSettings from "./SettingsPages/MainSettings";
import { useDispatch, useSelector } from "react-redux";
import { RiArrowLeftSLine } from "react-icons/ri";
import { updateDataFlow } from "../store/dataFlow/actions";

export function AllSettings() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(null);
  const { dataFlow } = useSelector((state) => state.dataFlow);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleOpen = (value) => {
    setOpen(!open);
    handleDataFlow("main");
  };
  const handleDataFlow = (text) => {
    dispatch(updateDataFlow(text));
  };

  return (
    <>
      <ListItem
        key={3}
        disablePadding
        className="m-0"
        onClick={() => handleOpen()}
      >
        <ListItemButton selected={false}>
          <ListItemIcon>
            <Avatar
              alt={user.firstName}
              src={user.avatar ? user.avatar : "/images/seeker.png"}
            />
          </ListItemIcon>
          <ListItemText> {user.firstName}</ListItemText>
          <ListItemIcon>
            <BsThreeDots className="!text-xl" />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        handler={handleOpen}
        sx={{
          backdropFilter: "blur(5px) sepia(5%)",
          // 👇 Another option to style Paper
          "& .MuiDialog-paper": {
            borderRadius: { xs: "0px", lg: "24px" },
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="flex justify-between items-center"
        >
          {dataFlow !== "main" && (
            <span onClick={() => handleDataFlow("main")}>
              <RiArrowLeftSLine className="!text-2xl cursor-pointer" />
            </span>
          )}
          {dataFlow === "main" ? "Settings" : dataFlow}
          <CloseIcon onClick={handleOpen} className="cursor-pointer" />
        </DialogTitle>

        <Divider />
        <DialogContent
          className={theme.palette.mode === "dark" && "bg-[#2b344a] !p-[10px]"}
        >
          {/* <Main /> */}
          <MainSettings />
        </DialogContent>
      </Dialog>
    </>
  );
}
