import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Input,
  DialogFooter,
  Textarea,
} from "@material-tailwind/react";
import * as messages from "../../../services/messages";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import { toast } from "react-toastify";

function EditWebMetaData(props) {
  const { open, toogleOpenEditor, id } = props;
  const [scrapedContent, setScrapedContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const saveContent = useMutation(
    (id) => {
      return http.put(`train/website/update`, scrapedContent, null);
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        toogleOpen();
      },
      onError: (error) => {
        errorFunc(error);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const toogleOpen = () => {
    if (loading) return false;
    if (open) {
      setScrapedContent("");
    }
    toogleOpenEditor();
  };

  const onChangeContent = (e) => {
    if (loading) return false;
    setScrapedContent({ ...scrapedContent, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    saveContent.mutate(id);
  };

  const getWebDetails = useMutation(
    (webId) => {
      return http.post(`train/website/findById`, { id: webId });
    },
    {
      onSuccess: ({ data }) => {
        setScrapedContent(data.website);
      },
      onError: (error) => {
        errorFunc(error);
      },
      onSettled: () => {
        setLoading(false);
      },
      onMutate: () => {
        setLoading(true);
      },
    }
  );

  const getWebDetailFunc = () => {
    if (id === null) return false;
    getWebDetails.mutate(id);
  };

  useEffect(() => {
    getWebDetailFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Dialog
      open={open}
      handler={toogleOpen}
      className="h-140"
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Edit Metadata : </DialogHeader>

      <DialogBody divider className="flex-col max-h-full  overflow-y-scroll">
        <div className="w-full flex justify-between gap-2 items-center">
          Website: {scrapedContent?.url}
          {/* <Button
            variant="gradient"
            className="flex items-center py-2 px-3"
            type="button"
            onClick={onScrapeData}
            disabled={loading}
          >
            Scrape Now
          </Button> */}
        </div>
        <form className="mt-2 mb-2  w-auto  max-w-screen-md sm:w-96 ">
          <div className=" flex flex-col gap-2 w-auto ">
            <div className="sm:w-auto md:w-[35rem]">
              <Input
                type="text"
                name="title"
                label="Document title"
                value={scrapedContent?.title}
                onChange={onChangeContent}
              />
            </div>
            <div className="sm:w-auto md:w-[35rem]">
              <Input
                type="text"
                name="author"
                label=" Author(s)"
                value={scrapedContent?.author}
                onChange={onChangeContent}
              />
            </div>
            <div className="sm:w-auto md:w-[35rem]">
              <Input
                name="publication_date"
                type="text"
                label="Publication date"
                value={scrapedContent?.publication_date}
                onChange={onChangeContent}
              />
            </div>
            <div className="sm:w-auto md:w-[35rem]">
              <Input
                name="doi"
                type="text"
                label="DOI. (Digital Object Identifier)"
                value={scrapedContent?.doi}
                onChange={onChangeContent}
              />
            </div>
            <div className="sm:w-auto md:w-[35rem]">
              <Input
                name="journal_name"
                type="text"
                label="Journal name"
                value={scrapedContent?.journal_name}
                onChange={onChangeContent}
              />
            </div>
            <div className="sm:w-auto md:w-[35rem]">
              <Textarea
                name="abstract"
                type="text"
                label="Abstract"
                value={scrapedContent?.abstract}
                onChange={onChangeContent}
              />
            </div>
            <div className="sm:w-auto md:w-[35rem]">
              <Input
                name="notes"
                type="text"
                label="Notes"
                value={scrapedContent?.notes}
                onChange={onChangeContent}
              />
            </div>
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={toogleOpen}
          className="mr-1"
          disabled={loading}
        >
          <span>Close</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          onClick={onSubmit}
          disabled={loading}
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default EditWebMetaData;
