import * as actionTypes from '../constants';


export function updateMycBot(botInfo: any, navigate: any, helpers: any) {
    return {
        type: actionTypes.UPDATE_BOT_MYC_REQUEST,
        payload: botInfo,
        navigate,
        helpers,
    };
}


export function getBotDataMyc( ): {
    type: string;
} {
    return {
        type: actionTypes.GET_BOT_DATA_MYC_REQUEST,
    };
}


export function handleUploadLogo(
    file: any,
    callBack: any
): {
    type: string;
    payload: any;
    callBack: any;
} {
    return {
        type: actionTypes.UPLOAD_IMAGE_REQUEST,
        payload: file,
        callBack,
    };
}

export function handleApplyforPro(
	callBack: any
): {
	type: string;
	callBack: any;
} {
	return {
		type: actionTypes.UPGRADE_TO_PRO_REQUEST,
		callBack,
	};
}