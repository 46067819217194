import {call, all, put, takeLatest} from 'redux-saga/effects';
import * as actionTypes from '../constants';
import {toast} from 'react-toastify';

import apiClient from '../apiClient';

import {getAccessToken} from '../../utils/jwt';

import {ServerResponse} from 'node:http';
import {AxiosResponse} from "axios";

async function shooterUpdateMycBot(userInfo: any) {
	return apiClient.put('/user/magic-myc', userInfo, {
		headers: {Authorization: getAccessToken()},
	});
}

function* updateBotMyc(): any {
	return yield takeLatest(
		actionTypes.UPDATE_BOT_MYC_REQUEST,
		function* (action: any) {
			try {
				const data: ServerResponse = yield call(
					shooterUpdateMycBot,
					action.payload
				);
				yield put({
					type: actionTypes.GET_BOT_DATA_MYC_REQUEST,
					payload: {
						...data,
					},
				});
				toast.success('Your Bot updated Successfully.');

			} catch (error: any) {
				action.helpers.setStatus({success: false});
				toast.error(
					error?.response?.data?.message
						? error?.response?.data.message
						: error.message
				);
				action.helpers.setErrors({
					submit: error?.response?.data?.message
						? error?.response?.data?.message
						: error?.message,
				});
				action.helpers.setSubmitting(false);
			}
		}
	);
}

async function shooterhandleUploadFiles(file: any) {
	return apiClient.post('/user/image-uploader', file, {
		headers: {
			Authorization: getAccessToken(),
			'Content-type': 'multipart/form-data',
		},
	});
}

function* handleUploadImages(): any {
	return yield takeLatest(
		actionTypes.UPLOAD_IMAGE_REQUEST,
		function* (action: any) {
			try {
				const data: ServerResponse = yield call(
					shooterhandleUploadFiles,
					action.payload
				);
				action.callBack({ status: 'success', returnData: data });
			} catch (error: any) {
				action.callBack({
					status: 'error',
					returnData: error?.response?.data?.message
						? error?.response?.data
						: error,
				});
			}
		}
	);
}


async function shooterGetBotMycData(): Promise<AxiosResponse<any, any>> {
	return apiClient.get(`/user/magic-myc`, {
		headers: {Authorization: getAccessToken()},
	});
}

function* getBotDataMyc(): any {
	return yield takeLatest(
		actionTypes.GET_BOT_DATA_MYC_REQUEST,
		function* (action: any) {
			try {
				const data: Promise<AxiosResponse<any, any>> = yield call(
					shooterGetBotMycData,
				);
				yield put({
					type: actionTypes.GET_BOT_DATA_MYC_SUCCESS,
					payload: {
						...data,
					},
				});
			} catch (error) {
				console.log(error);
			}
		}
	);
}
async function shooterhandleUpgradeToPro() {
	return apiClient.post('user/apply-for-pro',{}, {
		headers: {
			Authorization: getAccessToken(),
		},
	});
}

function* handleUpgradeToPro(): any {
	return yield takeLatest(
		actionTypes.UPGRADE_TO_PRO_REQUEST,
		function* (action: any) {
			try {
				const data: ServerResponse = yield call(
					shooterhandleUpgradeToPro,
				);
				action.callBack({ status: 'success', returnData: data });
			} catch (error: any) {
				action.callBack({
					status: 'error',
					returnData: error?.response?.data?.message
						? error?.response?.data
						: error,
				});
			}
		}
	);
}


export default function* runBlockingCallsExample() {
	yield all([
		updateBotMyc(),
		handleUploadImages(),
		getBotDataMyc(),
		handleUpgradeToPro(),
	]);
}
