import React, {useState} from "react";
import {useGoogleLogin} from '@react-oauth/google';
import {useNavigate} from "react-router-dom";
import axios from 'axios'
import {
    Button,
    Avatar
} from "@mui/material";
import {signInGoogle} from "../../store/auth/actions";
import {useDispatch} from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
}

const GoogleSignIn: React.FC<Props> = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signInWithGoogle = async (values: any): Promise<void> => {
        dispatch(
            signInGoogle(
                {userinfo: values},
                navigate,
            )
        );
    }

    const login = useGoogleLogin({
        onSuccess: async CredentialResponse => {
            setLoading(true)
            if (CredentialResponse) {
                await axios.get("https://www.googleapis.com/oauth2/v2/userinfo?access_token=" + CredentialResponse.access_token).then(async res => {
                    if (res) {
                        setLoading(false)
                        signInWithGoogle(res.data)
                    }
                }).catch(error => {
                    setLoading(false)
                    console.log(error.response.data.message)
                })
            }
        },
    });

    return (
        <>
            <div className="google-services">

                <Button variant="outlined"
                        fullWidth
                        size="large"
                        sx={{
                            mt: 1,
                            borderRadius: '86px',
                            mb: '10px',
                            padding: '15px 22px'
                        }}
                        startIcon={
                            !loading ? <Avatar
                                alt="Remy Sharp"
                                src={'/images/google.svg'}
                                sx={{width: 24, height: 24}}
                            /> : ""

                        } onClick={() => login()}>
                    {!loading ? "Sign in with Google" : <CircularProgress/>}
                </Button>
            </div>
            </>
    )
}

export default GoogleSignIn;