import {
  CardBody,
  Typography,
  Chip,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import http from "../../services/http-common";
import { useMainController, setSessionExpired } from "../../context";
import { formatDate } from "../../lib/util";
import EditUserDetails from "./users/EditUserDetails";
import ApproveUpgrade from "./users/ApproveUpgrade";

export function RequestsForUpgrade() {
  const [users, setUsers] = useState([]);
  const [dispatch] = useMainController();

  const getUsers = useMutation(
    () => {
      return http.get(
        `user/pro-applications`,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setUsers(data.users);
      },
      onError: () => {
        toast.error("Fetch data failed");
      },
    }
  );
  useEffect(() => {
    getUsers.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  window.document.title = "Mushgpt | Admin | Users";
  return (
    <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {[
              "fullname",
              "email",
              "role",
              "create at",
              "Profile",
              "Actions",
            ].map((el, index) => (
              <th
                key={index}
                className="border-b border-blue-gray-50 py-3 px-5 text-left"
              >
                <Typography
                  variant="small"
                  className="text-[11px] font-bold uppercase text-blue-gray-400"
                >
                  {el}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.map((val, key) => {
            const className = `py-3 px-5 ${
              key === 0 ? "" : "border-b border-blue-gray-50"
            }`;

            return (
              <tr key={key}>
                <td className={className}>
                  <div className="flex items-center gap-4">
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-semibold"
                      >
                        {val.firstName}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={className}>
                  <div className="flex items-center gap-4">
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-semibold"
                      >
                        {val.email}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={className}>
                  <Chip
                    variant="gradient"
                    color={"blue-gray"}
                    value={val.role}
                    className="py-0.5 px-2 text-[11px] font-medium"
                  />
                </td>
                <td className={className}>
                  <div className="flex items-center gap-4">
                    <div>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-semibold"
                      >
                        {formatDate(val.created_at)}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={className}>
                  <EditUserDetails
                    title={"Show"}
                    user={val && val}
                    getUsers={getUsers}
                  />
                </td>
                <td className={className}>
                  <ApproveUpgrade user={val && val} getUsers={getUsers} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </CardBody>
  );
}

export default RequestsForUpgrade;
