import * as actionTypes from "../constants";

export function updateDataFlow(data: string): {
  type: string;
  payload: string;
} {
  return {
    type: actionTypes.CHANGE_DATA_FLOW,
    payload: data,
  };
}
