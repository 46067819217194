import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { useTheme } from "@mui/material";

const IntegrationCard = ({ icon, title, desc, processComp }) => {
  const theme = useTheme();

  return (
    <>
      <Card
        className={`group w-full sm:w-[300px] sm:m-0 mt-8 mx-4 ${
          theme.palette.mode === "dark" && "bg-[#414559]"
        } `}
      >
        <CardBody>
          <span className={`block group-hover:hidden`}>{icon}</span>
          <Typography
            variant="h6"
            color={theme.palette.mode === "dark" ? "white" : "blue-gray"}
            className="mb-2"
          >
            {title}
          </Typography>
          <Typography
            variant="paragraph"
            className={`text-sm  ${
              theme.palette.mode === "dark" && "!text-[#9b9da8]"
            }`}
          >
            {desc}
          </Typography>
        </CardBody>
        <CardFooter className="pt-0 hidden group-hover:block">
          <Button
            size="sm"
            variant="filled"
            color={theme.palette.mode === "dark" && "white"}
            fullWidth={true}
            onClick={processComp}
          >
            Integrate
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default IntegrationCard;
