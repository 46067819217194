import { Box } from "@mui/material";

import ContentEdit from "../components/chat/content-edit";
import ContentContainer from "../components/chat/content-container";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MagicMycQResponse from "../components/faqs/MagicMycQResponse";
import ReactGA from "react-ga4";
import EditChatFooter from "./Chat Settings/EditChatFooter";

const Chat = () => {
  const [showSuggestions, setShowSuggestions] = useState(true);
  const { loading } = useSelector((state: any) => state.msg);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "MushGPt by The Entheology Project",
    });
  }, []);
  return (
    <>
      <Box
        id="scrollableDiv"
        className="max-h-[calc(100vh-200px)] md:max-h-[calc(100vh-190px)] lg:max-h-[calc(100vh-190px)] xl:max-h-[calc(100vh-190px)] "
        sx={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <ContentContainer
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
        />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          minHeight: "90px",
          width: "-webkit-fill-available",
          paddingRight: "40px",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Box sx={{ padding: "0px" }}>
          <EditChatFooter />
        </Box>
        <Box>
          {!loading && (
            <MagicMycQResponse
              setShowSuggestions={setShowSuggestions}
              showSuggestions={showSuggestions}
            />
          )}
          <ContentEdit setShowSuggestions={setShowSuggestions} />
        </Box>
      </Box>
    </>
  );
};

export default Chat;
