import React, { CSSProperties, useEffect, useState } from "react";
import "./style.css";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Button } from "@material-tailwind/react";

export type Theme = "light" | "dark";

interface SmartBannerProps {
  theme?: Theme;
  background?: string;
  textColor?: string;
  titleColor?: string;
  buttonColor?: string;
  closeIconColor?: string;
}

export const SmartBanner: React.FC<SmartBannerProps> = ({
  theme = "light",
  background,
  buttonColor,
}) => {
  const [showSmartBanner, setShowSmartBanner] = useState(false);
  const [buttonLink, setButtonLink] = useState("");
  const backgroundStyle: CSSProperties = background ? { background } : {};
  const buttonColorStyle: CSSProperties = buttonColor
    ? { color: buttonColor }
    : {};

  const onCloseBanner = () => {
    setShowSmartBanner(false);
  };

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent;
      // alert(userAgent.match("Android"));
      if (userAgent.match("Android")) {
        setShowSmartBanner(true);
        setButtonLink(
          "https://play.google.com/store/apps/details?id=com.entheology.mushgpt&pcampaignid=web_share&pli=1"
        );
      }

      if (
        // navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i)
      ) {
        setShowSmartBanner(true);
        setButtonLink(
          "https://apps.apple.com/us/app/mushgpt-psychedelic-ai-chatbot/id6470272942"
        );
      }
    };

    handleDeviceDetection();
  }, []);

  return (
    <>
      {showSmartBanner && (
        <div
          className={`smartbanner smartbanner-${theme}`}
          style={backgroundStyle}
        >
          <div className="smartbanner-row">
            <button
              type="button"
              onClick={onCloseBanner}
              className="smartbanner-close-button"
            >
              <XMarkIcon width={"20px"} />
            </button>
            <div className="smartbanner-row smartbanner-space-right">
              <img
                src={"/images/app-icon-sq.png"}
                alt="MushGpt"
                role="presentation"
                className="smartbanner-img"
              />
              <div className="smartbanner-column smartbanner-space-left">
                <p className="smartbanner-text smartbanner-small-font">
                  Download the MushGPT mobile app{" "}
                  <a
                    href={buttonLink}
                    className="text-blue-700"
                    target="_blank"
                    role="button"
                    style={buttonColorStyle}
                    rel="noreferrer"
                  >
                    here
                  </a>
                  !
                </p>
              </div>
            </div>
          </div>
          <a
            href={buttonLink}
            className={`smartbanner-link-${theme} smartbanner-link`}
            target="_blank"
            role="button"
            style={buttonColorStyle}
            rel="noreferrer"
          >
            <Button size="sm" variant="gradient">
              Go
            </Button>
          </a>
        </div>
      )}
    </>
  );
};
