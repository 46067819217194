import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IconButton,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import { useTheme } from "@mui/material";

function SimplePagiNation(props) {
  const [pageSize, setPageSize] = useState(props.pageSize);
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const theme = useTheme();

  const currenPageSelectRef = useRef();

  useEffect(() => {
    currenPageSelectRef.current.childNodes[0].style.minWidth = "80px";
  }, []);

  useEffect(() => {
    if (props.pageSize !== pageSize) {
      setCurrentPage(
        Math.ceil(((currentPage - 1) * pageSize + 1) / props.pageSize)
      );
      setPageSize(props.pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageSize]);

  useEffect(() => {
    if (props.currentPage !== currentPage) {
      setCurrentPage(props.currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPage]);

  useEffect(() => {
    if (props.pageSize !== pageSize || props.currentPage !== currentPage) {
      props.onChangePage && props.onChangePage(currentPage, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const next = () => {
    if (currentPage === props.totalPage) return;

    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
  };

  const moveFirst = () => {
    setCurrentPage(1);
  };
  const moveEnd = () => {
    setCurrentPage(props.totalPage);
  };

  const onChagePageSize = (e) => {
    setPageSize(Number(e));
  };

  const onChageCurrentPage = (val) => {
    setCurrentPage(Number(val));
  };

  const pageSizeOptions = useMemo(() => {
    return props.pageSizeKind.map((item) => (
      <Option value={item.value.toString()} key={item.value}>
        {item.name}
      </Option>
    ));
  }, [props.pageSizeKind]);

  const pageSelectOptions = useMemo(() => {
    let pages = [];
    for (let i = 0; i < props.totalPage; i++) {
      pages.push(
        <Option value={(i + 1).toString()} key={i}>
          {i + 1}
        </Option>
      );
    }
    return pages;
  }, [props.totalPage]);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-8 md:w-auto md:flex-row">
      <div className="flex w-auto items-center gap-4 md:w-max">
        <IconButton
          size="sm"
          variant="outlined"
          color={theme.palette.mode === "dark" ? "white" : "blue-gray"}
          onClick={moveFirst}
          disabled={currentPage === 1}
        >
          <ChevronDoubleLeftIcon strokeWidth={2} className={`h-4 w-4`} />
        </IconButton>
        <IconButton
          size="sm"
          variant="outlined"
          color={theme.palette.mode === "dark" ? "white" : "blue-gray"}
          onClick={prev}
          disabled={currentPage === 1}
        >
          <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
        </IconButton>
        <div className="flex items-center gap-2">
          <div className="" ref={currenPageSelectRef}>
            <Select
              label="Page"
              className={`${theme.palette.mode === "dark" && "text-white"}`}
              labelProps={{
                className: theme.palette.mode === "dark" && "text-[#9e9e9e]",
              }}
              menuProps={{
                className: `max-h-[150px] ${
                  theme.palette.mode === "dark" &&
                  "!bg-[#202940] !text-[#cbd0df] "
                }`,
              }}
              value={currentPage.toString()}
              disabled={props.totalPage === 0}
              onChange={onChageCurrentPage}
            >
              {pageSelectOptions}
            </Select>
          </div>
          <Typography
            color="gray"
            className={`font-normal  ${
              theme.palette.mode === "dark" && "!text-[#cbd0df]"
            }`}
          >
            &nbsp; of{" "}
            <strong
              className={`font-normal  ${
                theme.palette.mode === "dark"
                  ? "!text-[#f0f1f6]"
                  : "text-blue-gray-900"
              }`}
            >
              {props.totalPage}
            </strong>
          </Typography>
        </div>
        <IconButton
          size="sm"
          variant="outlined"
          color={theme.palette.mode === "dark" ? "white" : "blue-gray"}
          onClick={next}
          disabled={currentPage === props.totalPage}
        >
          <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
        </IconButton>
        <IconButton
          size="sm"
          variant="outlined"
          color={theme.palette.mode === "dark" ? "white" : "blue-gray"}
          onClick={moveEnd}
          disabled={currentPage === props.totalPage}
        >
          <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
        </IconButton>
      </div>

      <div className="w-[200px] md:w-auto">
        <Select
          variant="static"
          className={` ${theme.palette.mode === "dark" && "text-white"}`}
          menuProps={{
            className: `max-h-[150px]  ${
              theme.palette.mode === "dark" && "!bg-[#202940] "
            }`,
          }}
          labelProps={{
            className: theme.palette.mode === "dark" && "text-[#9e9e9e]",
          }}
          label="PageSize"
          onChange={onChagePageSize}
          value={pageSize.toString()}
        >
          {pageSizeOptions}
        </Select>
      </div>
    </div>
  );
}

SimplePagiNation.defaultProps = {
  totalPage: 10000,
  currentPage: 1,
  pageSize: 10,
  pageSizeKind: [
    { name: "10 items per page", value: 10 },
    { name: "15 items per page", value: 15 },
    { name: "20 items per page", value: 20 },
    { name: "25 items per page", value: 25 },
  ],
};

export default SimplePagiNation;
