import {
  Chip,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateModel } from "../../store/messages/actions";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useMutation } from "react-query";
import http from "../../services/http-common";
import { setSessionExpired } from "../../context";
import { toast } from "react-toastify";

const SelectModel = () => {
  const dispatch = useDispatch();
  const [selectModel, setSelectModel] = useState(localStorage.getItem("model"));
  const [allModel, setAllModel] = useState(null);

  const handleChange = (text) => {
    dispatch(updateModel(text));
    localStorage.setItem("model", text);
    setSelectModel(text);
  };
  const getAllMModels = useMutation(
    () => {
      return http.post(
        `/llms/getall`,
        {
          isEnabled: true,
        },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setAllModel(data?.records);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      onMutate: () => {},
      onSettled: () => {},
    }
  );
  useEffect(() => {
    getAllMModels.mutate();
    if (!localStorage.getItem("model")) {
      localStorage.setItem("model", "GPT-4o");
      setSelectModel("GPT-4o");
    }
  }, []);

  return (
    <>
      <Menu
        placement="bottom-end"
        dismiss={{
          itemPress: true,
        }}
      >
        <MenuHandler>
          <div className="flex justify-start items-center gap-2 ">
            <Chip
              variant="outlined"
              size="sm"
              value={selectModel}
              className="w-[min-content] normal-case bg-[#000080] text-white rounded-full "
            />
            <IoIosArrowDown fontSize="small" />
          </div>
        </MenuHandler>
        <MenuList>
          {allModel?.map((model) => {
            return (
              <MenuItem
                key={model.name}
                onClick={() => {
                  handleChange(model.name);
                }}
              >
                <span className="flex justify-between items-center gap-2 cursor-pointer ">
                  {model.name} {model.status === "private" && "(Private)"}
                  {localStorage.getItem("model") === model.name && <FaCheck />}
                </span>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default SelectModel;
