import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";

// ** Import MUI
import {
  Box,
  FormControl,
  TextField,
  FormLabel,
  // Select,
  // MenuItem,
  Autocomplete,
  Grid,
  Button,
  Avatar,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isValidPhoneNumber } from "libphonenumber-js";
// import moment from 'moment';

import MuiPhoneNumber from "material-ui-phone-number";

import type { FC } from "react";

import { updateProfile } from "../../store/auth/actions";
import { CountryType, countries } from "../../utils/countries";
import { StatesType, states } from "../../utils/state";
import { useMounted } from "../../hooks/use-mounted";
import AlertDeleteAccount from "../../pages/alertDeleteAccount";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(255)
    .required("Required"),
  phoneNumber: Yup.string().required(),
  email: Yup.string().email("Must be a valid email").max(255).required(),
});

// ! Start Component
const ProfileSetting: FC = () => {
  const isMounted = useMounted();
  const dispatch = useDispatch();

  const { user } = useSelector((state: any) => state.auth);

  const [previewAvatar, setPreviewAvatar] = useState<any>(
    user.avatar ? user.avatar : "/images/seeker.png"
  );
  const [phoneError, setPhoneError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const imageSelect = (e: any) => {
    formik.setFieldTouched("avatar", true);
    formik.setFieldValue("avatar", e.currentTarget.files[0]);
    e?.currentTarget?.files[0] &&
      setPreviewAvatar(URL.createObjectURL(e.currentTarget.files[0]));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user.firstName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      avatar: user.avatar || "",
      address: user.address || "",
      city: user.city || "",
      country: user.country || "",
      state: user.state || "",
      zip: user.zip || "",
      companyName: user.companyName || "",
      websiteUrl: user.websiteUrl || "",
      supportPhone: user.supportPhone || "",
      supportEmail: user.supportEmail || "",

      submit: null,
    },
    validationSchema,
    onSubmit: async (values: any, helpers: any): Promise<void> => {
      if (!phoneError) {
        setLoading(true);
        var form_data = new FormData();

        interface Props {
          avatar: string | Blob;
          firstName: string;
          email: string;
          phoneNumber: string;
          // gender: boolean;
          // birthday: Date;
          address: string;
          country: string;
          state: string;
          city: string;
          companyName: string;
          websiteUrl: string;
          submit: null;
        }

        Object.keys(values).forEach((key) => {
          let value: string | Blob | Date = values[key as keyof Props] as
            | string
            | Blob
            | Date;
          // if (key === "birthday") {
          //   form_data.append(key, moment(value as Date).format("YYYY-MM-DD"));
          // } else if (key !== "submit" && value !== "") {
          if (key !== "submit" && value !== "") {
            form_data.append(key, value as string | Blob);
          }
        });
        dispatch(updateProfile(form_data, helpers, setLoading));
      } else {
        formik.setFieldError("phoneNumber", phoneError);
      }
    },
  });

  // const datePickerChange = (value: Date | null) => {
  //   formik.setFieldTouched("birthday", true);
  //   formik.setFieldValue("birthday", value, true);
  // };

  const onPhoneNumberChange = (c: any) => {
    formik.setFieldTouched("phoneNumber", true);
    formik.setFieldValue("phoneNumber", c);
    if (!isValidPhoneNumber(c)) {
      setPhoneError("Invalid Phone Number");
      // formik.setFieldError("phoneNumber", "Invalid Phone Number");
    } else {
      setPhoneError("");
    }
  };

  const onChangeCountry = (event: any, value: string) => {
    // if (String(formik.values.country) !== value && isMounted()) {
    if (String(formik.values.country) !== value) {
      formik.setFieldTouched("country", true);
      formik.setFieldValue("country", value);
    }
  };

  const onChangeState = (event: any, value: string) => {
    // if (String(formik.values.country) !== value && isMounted()) {
    if (String(formik.values.state) !== value) {
      formik.setFieldTouched("state", true);
      formik.setFieldValue("state", value);
    }
  };

  // const defaultCountry = (): CountryType => {
  // 	if (user.country) {
  // 		const country = countries.find((el) => el.label === user.country);
  // 		return {
  // 			code: country?.code || '',
  // 			label: country?.label || '',
  // 			phone: country?.phone || '',
  // 		};
  // 	}
  // 	const country = countries.find((el) => el.code === 'US');
  // 	return {
  // 		code: country?.code || '',
  // 		label: country?.label || '',
  // 		phone: country?.phone || '',
  // 	};
  // };

  return (
    <>
      <Box
        sx={{
          marginTop: "25px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              alignItems: "center",
              display: { lg: "flex", xs: "block" },
              mt: "20px",
              mb: "10px",
            }}
          >
            <Avatar
              src={previewAvatar}
              sx={{
                height: 118,
                width: 118,
                margin: { lg: "0px 20px 0px 0px", xs: "0px auto 20px auto" },
                "& .MuiAvatar-img": {
                  objectFit: "cover",
                },
              }}
            >
              <AccountCircleRoundedIcon fontSize="large" />
            </Avatar>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                className="hover:!text-[#000080]"
                size="large"
                color="primary"
                variant="outlined"
                component="label"
                sx={{ borderRadius: "9px" }}
              >
                Change Profile Picture
                <input
                  type="file"
                  name="avatar"
                  hidden
                  onChange={imageSelect}
                />
              </Button>
            </Box>
          </Box>
          <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
            <FormLabel sx={{ marginBottom: "8px" }}>Name</FormLabel>
            <TextField
              size="small"
              error={!!(formik.touched.firstName && formik.errors.firstName)}
              fullWidth
              helperText={
                (formik.touched.firstName && formik.errors.firstName) as string
              }
              name="firstName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.firstName}
            />
          </FormControl>
          <Grid container spacing={4}>
            <Grid item xs={12} lmg={6}>
              <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>Email</FormLabel>
                <TextField
                  size="small"
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={
                    (formik.touched.email && formik.errors.email) as string
                  }
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                  autoComplete="aa"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} lmg={6}>
              <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>Phone number</FormLabel>
                <MuiPhoneNumber
                  size="small"
                  defaultCountry="us"
                  variant="outlined"
                  error={
                    !!(formik.touched.phoneNumber && formik.errors.phoneNumber)
                  }
                  fullWidth
                  helperText={
                    (formik.touched.phoneNumber &&
                      formik.errors.phoneNumber) as string
                  }
                  name="phoneNumber"
                  onBlur={formik.handleBlur}
                  onChange={onPhoneNumberChange}
                  type="text"
                  value={formik.values.phoneNumber}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} lg={12}>
              <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>Company name</FormLabel>
                <TextField
                  size="small"
                  fullWidth
                  name="companyName"
                  error={
                    !!(formik.touched.companyName && formik.errors.companyName)
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.companyName}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>Address</FormLabel>
                <TextField
                  size="small"
                  fullWidth
                  name="address"
                  error={!!(formik.touched.address && formik.errors.address)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} lmg={6}>
              <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>City</FormLabel>
                <TextField
                  size="small"
                  fullWidth
                  name="city"
                  error={!!(formik.touched.city && formik.errors.city)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                />
              </FormControl>
            </Grid>
            {isMounted() && (
              <Grid item xs={12} lmg={6}>
                <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                  <FormLabel sx={{ marginBottom: "8px" }}>State</FormLabel>
                  <Autocomplete
                    size="small"
                    id="country-select-demo"
                    options={states}
                    defaultValue={
                      // {
                      // 	...defaultCountry(),
                      // }
                      user.state &&
                      states.find((el: any) => el.label === user.state)
                      // : states.find((el) => el.code === "3956")
                    }
                    autoHighlight
                    getOptionLabel={(option: StatesType) => option.label}
                    onInputChange={onChangeState}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="state"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} lmg={6}>
              <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                <FormLabel sx={{ marginBottom: "8px" }}>Zip</FormLabel>
                <TextField
                  size="small"
                  error={!!(formik.touched.zip && formik.errors.zip)}
                  fullWidth
                  helperText={
                    (formik.touched.zip && formik.errors.zip) as string
                  }
                  name="zip"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.zip}
                />
              </FormControl>
            </Grid>
            {isMounted() && (
              <Grid item xs={12} lmg={6}>
                <FormControl fullWidth sx={{ mt: 1, flexGrow: 1, mr: 10 }}>
                  <FormLabel sx={{ marginBottom: "8px" }}>Country</FormLabel>
                  <Autocomplete
                    id="country-select-demo"
                    options={countries}
                    defaultValue={
                      // {
                      // 	...defaultCountry(),
                      // }
                      user.country
                        ? countries.find((el: any) => el.label === user.country)
                        : countries.find((el: any) => el.code === "US")
                    }
                    autoHighlight
                    getOptionLabel={(option: CountryType) => option.label}
                    onInputChange={onChangeCountry}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          {formik.errors.submit && (
            <FormHelperText error sx={{ mt: 3 }}>
              {formik.errors.submit as string}
            </FormHelperText>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "row", xs: "column-reverse" },
              justifyContent: { sm: "space-between", xs: "start" },
              alignItems: "center",
            }}
          >
            <AlertDeleteAccount />

            <LoadingButton
              loading={loading}
              size="small"
              sx={{
                mt: 2,
                borderRadius: "10px",
                height: "50.65px",
                mb: "20px",
                width: "205px",
              }}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ProfileSetting;
