import { useSelector } from "react-redux";
import { getSettingData } from "../sidebar";
import ContentSection from "./content-section";

const MainSettings = () => {
  const { dataFlow } = useSelector((state) => state.dataFlow);
  const sections = getSettingData();

  return (
    <>
      {dataFlow === "main" ? (
        <>
          {sections.map((_item) => {
            return (
              <ContentSection key={_item.key} item={_item}></ContentSection>
            );
          })}
        </>
      ) : (
        sections[0].item.map((item) => {
          return dataFlow === item.title && item.option;
        })
      )}
    </>
  );
};

export default MainSettings;
