// ** Import type
import type { FC } from "react";

const Help: FC = () => {
  return (
    <>
      <h1>Coming Soon!</h1>
    </>
  );
};

export default Help;
