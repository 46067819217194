import {
  Input,
  Button,
  Typography,
  Select,
  Option,
  Card,
  CardHeader,
  CardBody,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  MenuList,
  MenuHandler,
  IconButton,
} from "@material-tailwind/react";
import {
  CloudArrowUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
// import * as messages from "../../../services/messages";
import http from "../../../services/http-common";
import { setSessionExpired, useMainController } from "../../../context";
import { useState, useEffect } from "react";
import Table from "../../../widgets/table";
import { formatDate } from "../../../lib/util";
import EditContent from "./EditContent";
import { useForm } from "react-hook-form";
import { FaCheckDouble, FaMagic, FaTrashAlt } from "react-icons/fa";
import EditWebMetaData from "./EditWebMetaData";
import { useTheme } from "@mui/material";

export function TrainWebContent() {
  const theme = useTheme();
  const [dispatch] = useMainController();
  const { handleSubmit, register } = useForm({
    mode: "onSubmit",
  });

  const queryClient = useQueryClient();

  const cp = queryClient.getQueryData("websites-cp");
  const ps = queryClient.getQueryData("websites-ps");
  const filters = queryClient.getQueryData("websites-filters");
  const sorters = queryClient.getQueryData("websites-sorters");

  const [saving, setSaving] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("title");
  const [webSites, setWebSites] = useState([]);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [editorOpen, setEditorOpen] = useState(false);
  const [editMetaDataOpen, setditMetaDataOpen] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [checkValues, setCheckValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(cp || 1);
  const [pageSize, setPageSize] = useState(ps || 50);
  const [totalPage, setTotalPage] = useState(10000);
  const [filterValues, setFilterValues] = useState(filters || {});
  const [sorterValues, setSorterValues] = useState(
    sorters || { updated_at: { desc: true } }
  );

  useQuery("websites-cp", () => currentPage);
  useQuery("websites-ps", () => pageSize);
  useQuery("websites-filters", () => filterValues);
  useQuery("websites-sorters", () => sorterValues);

  const tableHeaders = [
    {
      key: "url",
      name: "Website URL",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    { key: "userName", name: "registered by user", sorter: true },
    {
      key: "status",
      name: "status",
      filter: {
        type: "check",
        kinds: [
          { name: "ready", value: "ready" },
          { name: "scraping", value: "scraping" },
          { name: "scraped", value: "scraped" },
          { name: "scraping failed", value: "scraping failed" },
          { name: "training", value: "training" },
          { name: "trained", value: "trained" },
          { name: "training failed", value: "training failed" },
        ],
      },
      sorter: true,
    },
    { key: "updated_at", name: "update at", sorter: true },
    {
      key: "actions",
      name: "",
      default: (id) => (
        <Menu
          animate={{
            mount: { y: 0 },
            unmount: { y: 25 },
          }}
        >
          <MenuHandler>
            <Button
              className={`
            ${theme.palette.mode === "dark" && "!bg-[#f3f6ff] text-[#1a2035]"}`}
            >
              Action
            </Button>
          </MenuHandler>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleScrape(id);
                // bulkTrain()
              }}
              className={
                theme.palette.mode === "dark" && "bg-[#1a2035] text-[#f3f6ff]"
              }
            >
              Scrape
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                handletrain(id);
              }}
              className={
                theme.palette.mode === "dark" && "bg-[#1a2035] text-[#f3f6ff]"
              }
            >
              Train
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                handleEdit(id);
              }}
              className={
                theme.palette.mode === "dark" && "bg-[#1a2035] text-[#f3f6ff]"
              }
            >
              Edit
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                deleteFunc(id);
              }}
              className={
                theme.palette.mode === "dark" && "bg-[#1a2035] text-[#f3f6ff]"
              }
            >
              Delete
            </MenuItem>
            {theme.palette.mode === "dark" && (
              <hr className="m-0 !border-t-[#3b4357]" />
            )}
            <MenuItem
              onClick={() => {
                handleEditMetaData(id);
              }}
              className={
                theme.palette.mode === "dark" && "bg-[#1a2035] text-[#f3f6ff]"
              }
            >
              Edit Meta Data
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  const { isError, error, data } = useQuery(
    ["websites", currentPage, pageSize, filterValues, sorterValues],
    () => {
      if (
        filterValues[selectedFilter] &&
        filterValues[selectedFilter].length > 0
      ) {
        return http.post(
          `train/website/search`,
          // ?cp=${currentPage}&ps=${pageSize}${
          //   JSON.stringify(filterValues) !== "{}"
          //     ? `&filters=${JSON.stringify(filterValues)}`
          //     : ""
          // }${
          //   JSON.stringify(sorterValues) !== "{}"
          //     ? `&sorters=${JSON.stringify(sorterValues)}`
          //     : ""
          // }
          {
            cp: currentPage,
            ps: pageSize,
            filters: {
              [selectedFilter]: filterValues[selectedFilter],
            },
          },
          setSessionExpired,
          dispatch
        );
      } else {
        return http.get(
          `train/website/all?cp=${currentPage}&ps=${pageSize}${
            JSON.stringify(filterValues) !== "{}"
              ? `&filters=${JSON.stringify(filterValues)}`
              : ""
          }${
            JSON.stringify(sorterValues) !== "{}"
              ? `&sorters=${JSON.stringify(sorterValues)}`
              : ""
          }`,
          null,
          setSessionExpired,
          dispatch
        );
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      queryKey: ["websites", currentPage, pageSize], // Unique key for the query
      staleTime: 30000, // Keep data in cache for 30 seconds
      retryOnMount: true,
    }
  );

  useEffect(() => {
    if (isError) {
      console.log(error);
      toast.error("Fetch data failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (data && data.data) {
      const { websites, pageSize, total } = data.data;
      const websitesData = websites.map((website) => {
        return {
          ...website,
          key: website.id,
          url: (
            <Tooltip
              content={website.url}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <Typography
                variant="small"
                color="blue-gray"
                className={`font-semibold ${
                  theme.palette.mode === "dark" && "text-[#cdcdcd]"
                }`}
              >
                <a href={website.url} target="_blank" rel="noreferrer">
                  {website.url.length > 40
                    ? website.url.slice(0, 40) + "..."
                    : website.url}
                </a>
              </Typography>
            </Tooltip>
          ),
          userName: (
            <Typography
              variant="small"
              color="blue-gray"
              className={`font-semibold ${
                theme.palette.mode === "dark" && "text-[#cdcdcd]"
              }`}
            >
              {website?.uploadedBy
                ? website?.uploadedBy
                : website.user.firstName}
            </Typography>
          ),
          updated_at: (
            <Typography
              variant="small"
              color="blue-gray"
              className={`font-semibold ${
                theme.palette.mode === "dark" && "text-[#cdcdcd]"
              }`}
            >
              {formatDate(website.updated_at)}
            </Typography>
          ),
          status: (
            <Chip
              variant="gradient"
              color={status(website.status)}
              value={website.status}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
        };
      });
      setWebSites([...websitesData]);
      setTotalPage(Math.ceil(total / pageSize));
    }
  }, [data]);

  const onChangeWebsiteUrl = (e) => {
    setWebsiteUrl(e.target.value);
  };

  const onSearchURL = (data) => {
    let selectedFilterVar = selectedFilter;
    setFilterValues((vals) => {
      return {
        ...vals,
        [selectedFilterVar]: data.url,
      };
    });
  };

  const errorFunc = (error) => {
    let message = error.response.data.message;
    toast.error(message);
  };

  const registerWebsite = useMutation(
    (url) => {
      return http.post(
        "train/website",
        { url: url },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: () => {
        toast.success("Successfully registered!");
        setWebsiteUrl("");
      },
      onError: (error) => {
        errorFunc(error);
      },
      onSettled: () => {
        setSaving(false);
      },
      onMutate: () => {
        setSaving(true);
      },
    }
  );

  const onSubmit = () => {
    registerWebsite.mutate(websiteUrl);
  };

  const trainWebSiteContent = useMutation(
    (id) => {
      return http.post(
        `train/website/${id}/train`,
        {},
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (err) => {
        errorFunc(err);
      },
    }
  );

  const scrapeWebSiteContent = useMutation(
    (id) => {
      return http.post(
        `/train/website/${id}/scrape`,
        {},
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
      },
      onError: (err) => {
        errorFunc(err);
      },
    }
  );

  const handletrain = (id) => {
    const val = webSites.find((el) => el.id === id);
    switch (val.status) {
      case "trained": {
        toast.warning("Already trained");
        break;
      }
      case "training": {
        toast.warning("Training Now");
        break;
      }
      case "ready":
      case "scraping failed": {
        toast.warning("Sorry! Please scrap the webiste first.");
        break;
      }
      case "scraping": {
        toast.warning(
          "Now scraping the website. Please try after complete scraping."
        );
        break;
      }
      default: {
        trainWebSiteContent.mutate(val.id);
      }
    }
  };

  const handleScrape = (id) => {
    const val = webSites.find((el) => el.id === id);
    switch (val.status) {
      case "training": {
        toast.warning("Sorry! Please try after complete training");
        break;
      }
      case "scraping": {
        toast.warning("Already scraping.");
        break;
      }
      default: {
        scrapeWebSiteContent.mutate(id);
      }
    }
  };

  const deleteWebsiteRecord = useMutation(
    (id) => {
      return http.delete(`train/website/${id}`, null);
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted");
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );

  const deleteFunc = (id) => {
    const val = webSites.find((el) => el.id === id);
    switch (val.status) {
      case "training": {
        toast.warning("Sorry! Please try after complete training");
        break;
      }
      case "scraping": {
        toast.warning("Sorry! Please try after complete scraping.");
        break;
      }
      default: {
        deleteWebsiteRecord.mutate(id);
      }
    }
  };

  const handleEdit = (id) => {
    const val = webSites.find((el) => el.id === id);

    switch (val.status) {
      case "ready":
      case "scraping failed": {
        toast.warning("Sorry! Please try after scrap the website");
        break;
      }
      case "scraping": {
        toast.warning("Sorry! Please try after scraping is completed.");
        break;
      }
      case "training": {
        toast.warning(
          "The bot is training with the content now, so please try after it is completed"
        );
        break;
      }
      default: {
        setContentId(val.id);
        toogleOpenEditor();
      }
    }
  };

  const handleEditMetaData = (id) => {
    const val = webSites.find((el) => el.id === id);

    switch (val.status) {
      case "ready":
      case "scraping failed": {
        toast.warning("Sorry! Please try after scrap the website");
        break;
      }
      case "scraping": {
        toast.warning("Sorry! Please try after scraping is completed.");
        break;
      }
      case "training": {
        toast.warning(
          "The bot is training with the content now, so please try after it is completed"
        );
        break;
      }
      default: {
        setContentId(val.id);
        toogleOpenEditorMetaData();
      }
    }
  };

  const toogleOpenEditor = () => {
    if (editorOpen === true) {
      setContentId(null);
    }
    setEditorOpen(!editorOpen);
  };

  const toogleOpenEditorMetaData = () => {
    if (editMetaDataOpen === true) {
      setContentId(null);
    }
    setditMetaDataOpen(!editMetaDataOpen);
  };

  const status = (value) => {
    switch (value) {
      case "ready": {
        return "blue";
      }
      case "scraping": {
        return "purple";
      }
      case "scraping failed": {
        return "red";
      }
      case "scraped": {
        return "cyan";
      }
      case "traning": {
        return "teal";
      }
      case "training failed": {
        return "pink";
      }
      case "trained": {
        return "green";
      }
      default: {
      }
    }
  };

  const onChangeCheckValues = (values) => {
    setCheckValues(values);
  };

  const bulkScrapeRequest = useMutation(
    () => {
      return http.post(
        "train/website/bulk/scrape",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );

  const bulkScrape = () => {
    bulkScrapeRequest.mutate();
  };

  const bulkTrainRequeset = useMutation(
    () => {
      return http.post(
        "train/website/bulk/train",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );

  const bulkTrain = () => {
    bulkTrainRequeset.mutate();
  };

  const bulkDeleteRequest = useMutation(
    () => {
      return http.post(
        "train/website/bulk/delete",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        setCheckValues([]);
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const bulkDelete = () => {
    bulkDeleteRequest.mutate();
  };

  const actions = (
    <>
      <Tooltip content="scrape">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkScrape}
        >
          <FaMagic />
        </IconButton>
      </Tooltip>
      <Tooltip content="train">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkTrain}
        >
          <FaCheckDouble />
        </IconButton>
      </Tooltip>
      <Tooltip content="delete">
        <IconButton
          variant="text"
          className="rounded-full"
          color="red"
          onClick={bulkDelete}
        >
          <FaTrashAlt />
        </IconButton>
      </Tooltip>
    </>
  );

  const onChangePagination = (cp, ps) => {
    setCurrentPage(cp);
    setPageSize(ps);

    queryClient.setQueryData("websites-cp", cp);
    queryClient.setQueryData("websites-ps", ps);
  };

  const onChangeFilter = (filterValues) => {
    setFilterValues(filterValues);
    queryClient.setQueryData("books-filters", { ...filterValues });
  };

  const onChangeSorter = (sorterValues) => {
    setSorterValues({ ...sorterValues });
    queryClient.setQueryData("books-sorters", { ...sorterValues });
  };

  const onChageSelectedFilter = (val) => {
    setSelectedFilter(val);
  };

  window.document.title = "Mushgpt | Admin | Websites";

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <div className="flex flex-col gap-6">
        <Typography
          color={theme.palette.mode === "dark" ? "white" : "gray"}
          variant="h6"
        >
          Register Website
        </Typography>
        <div className="flex h-full w-full flex-row items-center gap-6">
          <Input
            labelProps={{
              className: theme.palette.mode === "dark" && "!text-[#9e9e9e]",
            }}
            className={`border-neutral-300 ${
              theme.palette.mode === "dark"
                ? "!text-white border-neutral-600 focus:border-white"
                : "text-neutral-700"
            }  file:bg-neutral-100 file:text-neutral-700 hover:file:bg-neutral-200 focus:border-primary dark:file:bg-neutral-700 dark:file:text-neutral-100  relative m-0 block w-full min-w-0 flex-auto rounded border border-solid bg-clip-padding px-3 py-[0.32rem] text-base font-normal transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:px-3 file:py-[0.32rem] file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] `}
            type="text"
            name="website_address"
            label="Website URL"
            value={websiteUrl}
            onChange={onChangeWebsiteUrl}
          />
          <Button
            variant="gradient"
            color={theme.palette.mode === "dark" && "white"}
            className="flex items-center gap-3 py-2 px-3"
            disabled={saving}
            onClick={onSubmit}
          >
            <CloudArrowUpIcon strokeWidth={2} className="h-5 w-5" /> Register
          </Button>
        </div>
      </div>

      <Card className={` ${theme.palette.mode === "dark" && "!bg-[#202940]"}`}>
        <CardHeader
          variant="gradient"
          className={`mb-8 p-6 ${
            theme.palette.mode === "dark" ? "!bg-[#2b344a]" : "!bg-[#2b2b2b]"
          }`}
        >
          <Typography variant="h6" color="white">
            All Websites
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-auto px-2 pt-0 pb-2">
          <div className="flex w-full justify-end py-1 pr-2">
            <form onSubmit={handleSubmit(onSearchURL)}>
              <div className="flex ">
                <div className="flex items-center gap-2">
                  <div className="min-w-[100px] max-w-[110px] first:[&>div]:sm:!min-w-[100px] first:[&>div]:min-w-auto">
                    <Select
                      label="Search by"
                      labelProps={{
                        className:
                          theme.palette.mode === "dark" && "text-[#9e9e9e]",
                      }}
                      className={`min-w-[100px] max-w-[110px] ${
                        theme.palette.mode === "dark" && "text-white"
                      }`}
                      value={selectedFilter}
                      onChange={onChageSelectedFilter}
                    >
                      <Option value="url">URL</Option>
                      <Option value="title">Title</Option>
                      <Option value="author">Author</Option>
                      <Option value="abstract">Abstract</Option>
                    </Select>
                  </div>
                  <Input
                    icon={
                      <MagnifyingGlassIcon
                        className={`h-4 w-4 ${
                          theme.palette.mode === "dark" && "text-white"
                        }`}
                      />
                    }
                    className={`!border !border-gray-300 ${
                      theme.palette.mode === "dark"
                        ? "text-white"
                        : " focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                    } shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 `}
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    containerProps={{
                      className: "min-w-0 ",
                    }}
                    placeholder="Search"
                    {...register("url")}
                  />
                </div>
              </div>
            </form>
          </div>
          <Table
            tableHeaders={tableHeaders}
            data={webSites}
            paginationPosition="both"
            pagination={{
              pageSizeKind: [
                { name: "50 items per page", value: 50 },
                { name: "100 items per page", value: 100 },
                { name: "150 items per page", value: 150 },
                { name: "200 items per page", value: 200 },
              ],
              totalPage: totalPage,
              currentPage: currentPage,
              pageSize: pageSize,
            }}
            actions={actions}
            onChangeCheckValues={onChangeCheckValues}
            checkData={checkValues}
            checkable
            onChangePagination={onChangePagination}
            onChangeFilter={onChangeFilter}
            filters={filterValues}
            onChangeSorter={onChangeSorter}
            sorters={sorterValues}
          />
        </CardBody>
      </Card>
      <EditContent
        toogleOpenEditor={toogleOpenEditor}
        id={contentId}
        open={editorOpen}
      />
      <EditWebMetaData
        toogleOpenEditor={toogleOpenEditorMetaData}
        id={contentId}
        open={editMetaDataOpen}
      />
    </div>
  );
}

export default TrainWebContent;
