
import * as actionTypes from '../constants';
import {AllBotMessages} from "../../types/bot-messages";

interface BotState {
    isCreated: boolean;
    end: boolean;
    loading: boolean;
    botMessages: AllBotMessages[];
    answerError: boolean;
    customBot: object;
    botUniqueId: string;
    botDetails : [],
    BotSuggestions: []
}

const initialState: BotState = {
    isCreated: false,
    end: true,
    loading: false,
    botMessages: [],
    answerError: false,
    botDetails: [],
    customBot: {},
    botUniqueId: "",
    BotSuggestions: []


};

const reducer = (
    prevState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {

        case actionTypes.GET_BOT_DATA_MYC_SUCCESS:
            return {
                ...prevState,
                isCreated: true,
                customBot: action.payload?.data.bot,
            };
        default:
            return prevState;

    }
};

export default reducer;
