import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Textarea,
} from "@material-tailwind/react";
import * as messages from "../../../services/messages";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import { toast } from "react-toastify";

function EditContent(props) {
  const { open, toogleOpenEditor, id } = props;
  const [scrapedContent, setScrapedContent] = useState("");
  const [loading, setLoading] = useState(false);

  const errorFunc = (error) => {
    let message = error.response.data.message;
    switch (error.response.data.message) {
      case 500: {
        message = messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const saveContent = useMutation(
    (id) => {
      return http.put(
        `train/website/${id}/edit`,
        { content: scrapedContent },
        null
      );
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message);
        toogleOpen();
      },
      onError: (error) => {
        errorFunc(error?.response?.data?.message);
      },
      onMutate: () => {
        setLoading(true);
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const toogleOpen = () => {
    if (loading) return false;
    if (open) {
      setScrapedContent("");
    }
    toogleOpenEditor();
  };

  const onChangeContent = (e) => {
    if (loading) return false;
    setScrapedContent(e.target.value);
  };

  const onSubmit = () => {
    saveContent.mutate(id);
  };

  const getScrapedContent = useMutation(
    (id) => {
      return http.get(`train/website/${id}/edit`);
    },
    {
      onSuccess: ({ data }) => {
        setScrapedContent(data.content);
      },
      onError: (error) => {
        errorFunc(error);
      },
      onSettled: () => {
        setLoading(false);
      },
      onMutate: () => {
        setLoading(true);
      },
    }
  );

  const getScrapedFunc = () => {
    if (id === null) return false;
    getScrapedContent.mutate(id);
  };

  useEffect(() => {
    getScrapedFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Dialog
      open={open}
      handler={toogleOpen}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Scraped Content</DialogHeader>
      <DialogBody divider className="flex-col">
        <div className="mt-4">
          <Textarea
            label="Content"
            className="min-h-[30vh] overflow-y-auto overflow-x-hidden"
            value={scrapedContent}
            onChange={onChangeContent}
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={toogleOpen}
          className="mr-1"
          disabled={loading}
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          onClick={onSubmit}
          disabled={loading}
        >
          <span>Save</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default EditContent;
