import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";

import SideBar from "../components/sidebar";
import Header from "../components/header";
import { Helmet } from "react-helmet";

import metaDescriptor from "../utils/metaDestriptor.json";
// import type
import { FC, ReactNode } from "react";
interface LayoutsProps {
  children?: ReactNode;
}

const Layouts: FC<LayoutsProps> = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        maxWidth: "1920px",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <Grid
        item
        lg={4}
        xl={3}
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
          padding: "40px 40px 26px 40px",
        }}
      >
        <SideBar />
      </Grid>
      <Grid
        item
        xs={12}
        lg={8}
        xl={9}
        sx={{
          paddingLeft: { xs: "20px", md: "40px", lg: "0px" },
          paddingRight: { xs: "20px", md: "40px" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 999,
          }}
        >
          <Header />
          <Helmet>
            <meta
              property="og:title"
              content="MushGPT by The Entheology Project"
            />
            <meta
              property="og:description"
              content={
                "Learn about microdosing mushrooms & more at MushGPT.com: a friendly educational chatbot!"
              }
            />
            <meta
              property="og:image"
              content={metaDescriptor.hostname + "/images/social-icon.png"}
            />
            <meta property="og:url" content="https://dev-chat.mushgpt.com/" />
            <meta property="og:type" content="website" />
            <meta
              name="twitter:title"
              content="MushGPT by The Entheology Project"
            />
            <meta
              name="twitter:description"
              content="Learn about microdosing mushrooms & more at MushGPT.com: a friendly educational chatbot!"
            />
            <meta
              name="twitter:image"
              content={metaDescriptor.hostname + "/images/social-icon.png"}
            />
            <meta
              property="twitter:image"
              content="https://metatags.io/images/meta-tags.png"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="robots" content="max-image-preview:large" />
          </Helmet>
        </Box>
        {children}
      </Grid>
    </Grid>
  );
};

Layouts.propTypes = {
  children: PropTypes.node,
};

export default Layouts;
