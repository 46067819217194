import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Card,
    CardHeader,
    FormControl,
    FormLabel,
    CardContent,
    Stack,
    TextField,
    FormHelperText,
    Button,
    OutlinedInput,
    Typography,
    Box,
} from '@mui/material';
import {resetPass} from "../store/auth/actions";
interface Values {
    otp: string;
    password: string;
    submit: null;
}

const initialValues: Values = {
    otp: '',
    password: '',
    submit: null,
};

const validationSchema = Yup.object({
    otp: Yup.string().required('OPT is required'),
    password: Yup.string().max(255).required('New Password is required'),
});

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPassword] = useState<boolean>(false);


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: any, helpers: any): Promise<void> => {
            dispatch(
                resetPass(
                    { otp: values.otp, newPassword: values.password },
                    navigate,
                    helpers
                )
            );
        },
    });
    return (
        <Box
            // container
            sx={{
                height: '100vh',
                backgroundImage: 'url("/images/mgpt-bg-compressed.webp")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'flow-root',
            }}
            // alignItems="center"
            // justifyContent="center"
            // alignContent="center"
        >
            <Box
                // item
                sx={{
                    position: 'relative',
                    margin: 'auto',
                }}
            >
                <Box
                    sx={{ position: 'absolute', top: '-74px', left: 'calc(50% - 56px)' }}
                >
                    <Link to="/">
                        <img src="/images/SignIn logo.png" alt="MushGPT" />
                    </Link>
                </Box>
                <Card
                    elevation={16}
                    sx={{
                        // width: '28vw',
                        width: {
                            xs: '90vw',
                            sm: '490px',
                        },
                        // minWidth: {
                        // 	md: '400px',
                        // 	sm: 'cal(100% - 50px)',
                        // },
                        minHeight: '538px',
                        borderRadius: '30px',
                        padding: {
                            sm: '33px 42px',
                            xs: '33px 15px',
                        },
                        margin: 'auto',
                        marginTop: '100px',
                    }}
                >
                    <CardHeader sx={{ pb: 0 }} />
                    <CardContent>
                        <Box
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    color: 'primary.main',
                                    fontFamily: 'Poppins !important',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '1.875rem !important',
                                    lineHeight: 2.07,
                                }}
                            >
                                Reset Password
                            </Typography>
                        </Box>
                        <Typography
                            variant="subtitle2"
                            sx={{color: '#929292', fontSize: '16px !important',marginTop: '10px',textAlign: 'center'}}
                        >
                            Enter the verification code we just sent you on your email address.
                        </Typography>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <Stack spacing={3}
                                   sx={{
                                       marginTop : '10px'
                                   }}>
                                <FormControl>
                                    <FormLabel sx={{ marginBottom: '8px' }}>
                                        Enter OTP
                                    </FormLabel>
                                    <TextField
                                        autoFocus
                                        error={!!(formik.touched.otp && formik.errors.otp)}
                                        fullWidth
                                        helperText={formik.touched.otp && formik.errors.otp}
                                        name="otp"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type="text"
                                        value={formik.values.otp}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel sx={{ marginBottom: '8px' }}>New Password</FormLabel>
                                    <OutlinedInput
                                        error={
                                            !!(formik.touched.password && formik.errors.password)
                                        }
                                        fullWidth
                                        name="password"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type={showPassword ? 'text' : 'password'}
                                        value={formik.values.password}
                                    />
                                </FormControl>
                            </Stack>
                            {formik.errors.submit && (
                                <FormHelperText error sx={{ mt: 3 }}>
                                    {formik.errors.submit as string}
                                </FormHelperText>
                            )}
                            <Button
                                disabled={formik.isSubmitting}
                                fullWidth
                                size="large"
                                sx={{
                                    mt: 4,
                                    borderRadius: '86px',
                                    height: '60.65px',
                                    mb: '10px',
                                }}
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </form>
                        <Typography
                            variant="subtitle2"
                            sx={{ color: '#929292', fontSize: '16px !important' }}
                        >
                            Back to &nbsp;
                            <Link className="signup" to="/">
                                Sign In
                            </Link>
                        </Typography>
                    </CardContent>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '-113px',
                            left: 'calc(50% - 85.5px)',
                        }}
                    >
                        <img src="/images/image 7.png" alt="MushGPT" />
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default ResetPassword;
