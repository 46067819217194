import * as React from "react";
import { Avatar, Link, Divider, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { BootstrapList } from "../sidebar/sidebar-section";
import { AllSettings } from "../AllSettings";

export default function ProfileMenu({ sidebar = false }) {
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      {sidebar ? (
        <nav
          aria-label=""
          className={`main mailbox folders ${
            sidebar && "!absolute !py-2 bottom-0 w-full"
          }`}
        >
          <BootstrapList subheader={null}>
            <Divider />
            {/* <ThemeSettings /> */}
            <AllSettings />
          </BootstrapList>
        </nav>
      ) : (
        <IconButton
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            ml: 2,
          }}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Link
            underline="none"
            key="2"
            color="inherit"
            href="/profile"
            className="flex items-center gap-2 cursor-pointer"
          >
            <Avatar
              alt={user.firstName}
              src={user.avatar ? user.avatar : "/images/seeker.png"}
            />
          </Link>
        </IconButton>
      )}
    </>
  );
}
