import { Routes as CommonRoutes, Navigate, Route } from "react-router-dom";

// ** Import pages
import Chat from "./pages/chat";
import SignIn from "./pages/auth/signin";
import ForgetPassword from "./pages/auth/forgetPassword";
import Profile from "./pages/profile";
import SignUp from "./pages/auth/signup";
import Help from "./pages/help";
import Dedicate from "./pages/dedicate";
import { TrainBook, TrainWebContent } from "./pages/adminDashboard/train";

import Layout from "./layouts";
import AuthProvider from "./layouts/auth-provider";

// ** import type
import type { FC, ReactNode } from "react";
import VerifyPage from "./pages/verifyPage";
import NotVerifyPage from "./pages/notverifyPage";
import { Users } from "./pages/adminDashboard";
import ResetPassword from "./pages/reset";
import { useSelector } from "react-redux";
import { OtherAdmin } from "./pages/Personality/Admin/other";
import PersonalityAdmin from "./pages/Personality/Admin/other/personalityAdmin";
import BotSuggestions from "./pages/Embedding/BotSuggestions";
import Integrations from "./pages/Embedding/Integrations";
// import { DedicatePdf, DedicateWebsite } from "./components/dedicate";
// import UpgradeToPro from "./components/Upgrade/UpgradeToPro";

interface MainLayoutProps {
  children?: ReactNode;
}

const Wrapper: FC<MainLayoutProps> = ({ children }) => {
  return (
    <AuthProvider>
      <Layout>{children}</Layout>
    </AuthProvider>
  );
};

const Routes = () => {
  const { user } = useSelector((state: any) => state.auth);

  return (
    <CommonRoutes>
      <Route path="/" element={<SignIn />}></Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/forget-password" element={<ForgetPassword />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>
      <Route
        path="/chat"
        element={
          <Wrapper>
            <Chat />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/data"
        element={
          <Wrapper>
            <Dedicate />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/documents"
        element={<Wrapper>{user?.role === "admin" && <TrainBook />}</Wrapper>}
      ></Route>
      <Route
        path="/personality"
        element={
          <Wrapper>{user?.role === "admin" && <PersonalityAdmin />}</Wrapper>
        }
      ></Route>
      <Route
        path="/system-settings"
        element={
          <Wrapper>
            <OtherAdmin />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/integration"
        element={
          <Wrapper>
            <Integrations />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/suggestions"
        element={
          <Wrapper>
            <BotSuggestions />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/websites"
        element={
          <Wrapper>{user?.role === "admin" && <TrainWebContent />}</Wrapper>
        }
      ></Route>
      <Route
        path="/users"
        element={<Wrapper>{user?.role === "admin" && <Users />}</Wrapper>}
      ></Route>
      <Route
        path="/profile"
        element={
          <Wrapper>
            <Profile />
          </Wrapper>
        }
      ></Route>
      <Route
        path="/help"
        element={
          <Wrapper>
            <Help />
          </Wrapper>
        }
      ></Route>
      <Route path="/verify/:id/:token" element={<VerifyPage />}></Route>
      <Route
        path="/notverify"
        element={
          <AuthProvider>
            <NotVerifyPage />
          </AuthProvider>
        }
      ></Route>
      <Route path="*" element={<Navigate to="/" />} />
    </CommonRoutes>
  );
};
export default Routes;
