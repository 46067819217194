import React from "react";
import { Typography
} from "@material-tailwind/react";
import {useMutation} from "react-query";
import http from "../../../services/http-common";
import {toast} from "react-toastify";
import * as messages from "../../../services/messages";
import {TrashIcon} from "@heroicons/react/24/solid";

const DeleteUser = ({user, getUsers}) => {
    const [userinfo] = React.useState({
        id: user.id
    });


    const deleteUser = useMutation(
        (id) => {
            const res = http.deleteUser(`user/delete`, {userId: {
                id: id
                }}, null);
            getUsers.mutate()
            return res;
        },
        {
            onSuccess: () => {
                toast.success("Successfully Deleted");
            },
            onError: (error) => {
                errorFunc(error);
            },
        }
    );
    const errorFunc = (error) => {
        let message = "";
        switch (error.response.status) {
            case 500: {
                message = error.response.data.message || messages.SERVER_ERROR;
                break;
            }
            case 404: {
                message = messages.NOT_FOUND_ERROR;
                break;
            }
            case 400: {
                message = messages.BAD_REQUEST;
                break;
            }
            default: {
                message = error.message;
            }
        }
        toast.error(message);
    };

    const deleteFunc = () => {
        userinfo && deleteUser.mutate(userinfo.id);
    };


    return (
        <>
            <Typography
                as="a"
                href="#"
                className="text-xs font-semibold text-blue-gray-600"
                onClick={() => deleteFunc()}
            >
                <TrashIcon className="h-4 w-4 "/>
            </Typography>
        </>
    );
}

export default DeleteUser;