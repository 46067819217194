import { FaLaptopCode, FaWordpress } from "react-icons/fa";
import IntegrationCard from "./IntegrationCard";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmbeddings } from "../../store/messages/actions";
import { useTheme } from "@mui/material";

const Integrations = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [scroll] = useState<DialogProps["scroll"]>("paper");
  const [clipboardText, setClipboardText] = useState("Click to copy");
  const [open, setOpen] = useState(false);
  const { embeddingMessage } = useSelector((state: any) => state.msg);

  const handleClose = () => {
    setOpen(false);
  };

  const HandleTooltipClose = () => {
    setTimeout(() => {
      setClipboardText("Click to copy");
    }, 200);
  };
  const descriptionElementRef = useRef<HTMLElement>(null);

  const codeHandler = () => {
    setOpen(true);
    dispatch(getEmbeddings());
  };

  const codeWordPressHandler = () => {
    //
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const data = [
    {
      icon: (
        <FaLaptopCode
          className={`text-6xl ${
            theme.palette.mode === "dark" ? "!text-white" : "!text-[#000080]"
          }`}
        />
      ),
      title: "Website (HTML/CSS)",
      desc: "Integrate Custom Bot with your Website and create new deals straight from the conversation.",
      processComp: codeHandler,
    },
    {
      icon: (
        <FaWordpress
          className={`text-6xl ${
            theme.palette.mode === "dark" ? "!text-white" : "!text-[#000080]"
          }`}
        />
      ),
      title: "WordPress",
      desc: "Enhance your customer service and see your sales skyrocket in no time.",
      processComp: codeWordPressHandler,
    },
  ];
  return (
    <>
      <div
        className={`flex flex-wrap gap-4 min-h-[30rem] sm:min-h-0 p-7 justify-center sm:justify-start my-2
      `}
      >
        {data.map((_item, idx) => {
          return (
            <>
              <IntegrationCard
                icon={_item.icon}
                title={_item.title}
                desc={_item.desc}
                processComp={_item.processComp}
              />
            </>
          );
        })}
      </div>
      <>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            Installation guides below:
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <Typography className={"bg-white"} variant="subtitle1" gutterBottom>
              1. Paste this code snippet just before the {`</head>`} tag.
            </Typography>
            <DialogContentText
              className={"relative bg-indigo-50 mb-3"}
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <CopyToClipboard
                text={`<link rel="stylesheet" href="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/chatbot.css" /> \n <script src="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/script.js"></script>`}
                onCopy={() => {
                  // alert("copied");
                  setClipboardText("Copied");
                }}
              >
                <Box
                  className="clipboard-copy"
                  sx={{
                    // position: { xs: 'inherit', md: 'absolute' },
                    // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                    position: "absolute",
                    // marginLeft: 0,
                  }}
                >
                  <Tooltip
                    title={clipboardText}
                    placement="top"
                    onClose={HandleTooltipClose}
                  >
                    <IconButton aria-label="copy">
                      <ContentCopyIcon sx={{ height: "16px", width: "16px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CopyToClipboard>
              <pre className={"text-sm p-2 overflow-auto"}>
                {`<link rel="stylesheet" href="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/chatbot.css" /> \n <script src="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/script.js"></script>`}
              </pre>
            </DialogContentText>
            <br />
            <Typography
              className={"bg-white "}
              variant="subtitle1"
              gutterBottom
            >
              2. Paste this code snippet just in the {`<body>`} tag.
            </Typography>
            <DialogContentText
              className={"relative bg-indigo-50"}
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <CopyToClipboard
                text={embeddingMessage?.embedding}
                onCopy={() => {
                  // alert("copied");
                  setClipboardText("Copied");
                }}
              >
                <Box
                  className="clipboard-copy"
                  sx={{
                    // position: { xs: 'inherit', md: 'absolute' },
                    // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                    position: "absolute",
                    // marginLeft: 0,
                  }}
                >
                  <Tooltip
                    title={clipboardText}
                    placement="top"
                    onClose={HandleTooltipClose}
                  >
                    <IconButton aria-label="copy">
                      <ContentCopyIcon sx={{ height: "16px", width: "16px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CopyToClipboard>
              <pre className={"text-sm p-2 overflow-auto"}>
                {embeddingMessage?.embedding}
              </pre>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default Integrations;
