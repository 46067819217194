export interface StatesType {
	code: string;
	label: string;
	country: string;
}

export const states: readonly StatesType[] = [{"code":"3919","label":"Alabama","country":"US"},
{"code":"3920","label":"Alaska","country":"US"},
{"code":"3921","label":"Arizona","country":"US"},
{"code":"3922","label":"Arkansas","country":"US"},
{"code":"3923","label":"Byram","country":"US"},
{"code":"3924","label":"California","country":"US"},
{"code":"3925","label":"Cokato","country":"US"},
{"code":"3926","label":"Colorado","country":"US"},
{"code":"3927","label":"Connecticut","country":"US"},
{"code":"3928","label":"Delaware","country":"US"},
{"code":"3929","label":"District of Columbia","country":"US"},
{"code":"3930","label":"Florida","country":"US"},
{"code":"3931","label":"Georgia","country":"US"},
{"code":"3932","label":"Hawaii","country":"US"},
{"code":"3933","label":"Idaho","country":"US"},
{"code":"3934","label":"Illinois","country":"US"},
{"code":"3935","label":"Indiana","country":"US"},
{"code":"3936","label":"Iowa","country":"US"},
{"code":"3937","label":"Kansas","country":"US"},
{"code":"3938","label":"Kentucky","country":"US"},
{"code":"3939","label":"Louisiana","country":"US"},
{"code":"3940","label":"Lowa","country":"US"},
{"code":"3941","label":"Maine","country":"US"},
{"code":"3942","label":"Maryland","country":"US"},
{"code":"3943","label":"Massachusetts","country":"US"},
{"code":"3944","label":"Medfield","country":"US"},
{"code":"3945","label":"Michigan","country":"US"},
{"code":"3946","label":"Minnesota","country":"US"},
{"code":"3947","label":"Mississippi","country":"US"},
{"code":"3948","label":"Missouri","country":"US"},
{"code":"3949","label":"Montana","country":"US"},
{"code":"3950","label":"Nebraska","country":"US"},
{"code":"3951","label":"Nevada","country":"US"},
{"code":"3952","label":"New Hampshire","country":"US"},
{"code":"3953","label":"New Jersey","country":"US"},
{"code":"3954","label":"New Jersy","country":"US"},
{"code":"3955","label":"New Mexico","country":"US"},
{"code":"3956","label":"New York","country":"US"},
{"code":"3957","label":"North Carolina","country":"US"},
{"code":"3958","label":"North Dakota","country":"US"},
{"code":"3959","label":"Ohio","country":"US"},
{"code":"3960","label":"Oklahoma","country":"US"},
{"code":"3961","label":"Ontario","country":"US"},
{"code":"3962","label":"Oregon","country":"US"},
{"code":"3963","label":"Pennsylvania","country":"US"},
{"code":"3964","label":"Ramey","country":"US"},
{"code":"3965","label":"Rhode Island","country":"US"},
{"code":"3966","label":"South Carolina","country":"US"},
{"code":"3967","label":"South Dakota","country":"US"},
{"code":"3968","label":"Sublimity","country":"US"},
{"code":"3969","label":"Tennessee","country":"US"},
{"code":"3970","label":"Texas","country":"US"},
{"code":"3971","label":"Trimble","country":"US"},
{"code":"3972","label":"Utah","country":"US"},
{"code":"3973","label":"Vermont","country":"US"},
{"code":"3974","label":"Virginia","country":"US"},
{"code":"3975","label":"Washington","country":"US"},
{"code":"3976","label":"West Virginia","country":"US"},
{"code":"3977","label":"Wisconsin","country":"US"},
{"code":"3978","label":"Wyoming","country":"US"},
    ]
    ;
