import { useRef, useEffect, useState, useCallback } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch } from "react-redux";

// ** Import MUI
import { IconButton, Box, useTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

// ** Import Type
import { FC } from "react";

// ** Import action
import { getAnswer } from "../../store/messages/actions";
import AlertDeleteChat from "./alertDeleteChat";
interface ContentEditProps {
  setShowSuggestions: (showSuggestions: boolean) => void;
}

const ContentEdit: FC<ContentEditProps> = ({ setShowSuggestions }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [text, setText] = useState("");
  const inputBoxRef = useRef(null);

  const handleChange = (evt: any) => {
    const value = evt.target.value.replace(/<[^>]+>/g, "");
    setText(value);
  };

  const onSubmit = useCallback(() => {
    if (text !== "") {
      dispatch(
        getAnswer({
          finalChat: text,
          llm: localStorage.getItem("model")?.toLocaleLowerCase(),
        })
      );
      setShowSuggestions(false);
      setText("");
    }
  }, [text, dispatch]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak");
        } else {
          onSubmit();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <AlertDeleteChat setShowSuggestions={setShowSuggestions} />
        <ContentEditable
          className={`content-editable !p-[12px_24px] !rounded-md ${
            theme?.palette.mode === "dark" && "!bg-[#1a2035]"
          }`}
          data-text="Send a message"
          innerRef={inputBoxRef}
          html={text}
          onChange={handleChange}
        />
        <IconButton
          sx={{ position: "absolute", right: "5px" }}
          aria-label="Submit"
          onClick={onSubmit}
        >
          <SendIcon color="primary" />
        </IconButton>
      </Box>
    </>
  );
};

export default ContentEdit;
