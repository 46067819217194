import React from "react";
import { useMutation } from "react-query";
import http from "../../../services/http-common";
import { toast } from "react-toastify";
import * as messages from "../../../services/messages";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { Tooltip, IconButton } from "@material-tailwind/react";

const ApproveUpgrade = ({ user, getUsers }) => {
  const approve = useMutation(
    () => {
      const res = http.post(
        `user/approve-pro-applications`,
        { ids: [user?.id] },
        null
      );
      getUsers.mutate();
      return res;
    },
    {
      onSuccess: () => {
        toast.success("Successfully Approved.");
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = error.response.data.message || messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const handleApprove = () => {
    approve.mutate();
  };

  return (
    <>
      <Tooltip content="Approve">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={handleApprove}
        >
          <IoCheckmarkDoneSharp />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ApproveUpgrade;
