import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// ** Import Actions
import { getUserInfo } from '../store/auth/actions';

interface Props {
	children: JSX.Element | JSX.Element[];
}

const AuthProvider = ({ children }: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user, isAuthenticated } = useSelector((state: any) => state.auth);
	const [didMount, setDidMount] = useState(false);

	useEffect(() => {
		setDidMount(true);
		!isAuthenticated &&
			!localStorage.getItem('accessToken') &&
			navigate('/signin');
		if (!isAuthenticated) {
			let accessToken = localStorage.getItem('accessToken');
			if (accessToken !== 'undefined') {
				dispatch(getUserInfo(accessToken, navigate));
			}
		}
	}, [navigate, didMount, isAuthenticated, dispatch]);

	useEffect(() => {
		if (user && user.emailVerify === false) {
			navigate('/notverify');
		}
	}, [user, navigate]);

	return <>{didMount && isAuthenticated && children}</>;
};

export default AuthProvider;
