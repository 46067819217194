import { useState } from "react";

import {
  Button,
  Dialog,
  DialogHeader,
  Textarea,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { IconButton } from "@mui/material";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import http from "../../services/http-common";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useMainController, setSessionExpired } from "../../context";
import { useSelector } from "react-redux";

const ResponseFeedback = ({ questionId, feedback }) => {
  const [isProvidedFeedback, setIsProvidedFeedback] = useState(null);
  const [dispatch] = useMainController();
  const [open, setOpen] = useState(false);
  const [responseFeedback, setResponseFeedback] = useState({
    thumbup: false,
    thumbdown: false,
    description: "",
    chatId: questionId,
  });
  const { messages } = useSelector((state) => state.msg);

  const errorFunc = (error) => {
    let message;
    switch (error.response.status) {
      case 500: {
        message = error.response.data.message || messages.SERVER_ERROR;
        break;
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR;
        break;
      }
      case 400: {
        message = messages.BAD_REQUEST;
        break;
      }
      default: {
        message = error.message;
      }
    }
    toast.error(message);
  };

  const postFeedback = useMutation(
    () => {
      return http.post(
        `/user/feedback/create`,
        responseFeedback,
        null,
        setSessionExpired,
        dispatch
      );
    },
    {
      onSuccess: ({ data }) => {
        setOpen(false);
        responseFeedback.thumbup
          ? (messages[0].feedback = true)
          : (messages[0].feedback = false);
        toast.success("Thanks for your precious feedback!");
        responseFeedback.thumbup
          ? setIsProvidedFeedback("pos")
          : setIsProvidedFeedback("neg");
        setResponseFeedback({
          thumbup: false,
          thumbdown: false,
          description: "",
          chatId: "",
        });
      },
      onError: (error) => {
        errorFunc(error);
      },
    }
  );
  const handleChange = (e) => {
    setResponseFeedback((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
    setResponseFeedback((prevState) => ({ ...prevState, chatId: questionId }));
  };
  const handleOpen = () => setOpen(!open);

  const handlePositive = () => {
    responseFeedback.thumbup = true;
    setResponseFeedback((prevState) => ({ ...prevState, thumbup: true }));
    postFeedback.mutate();
  };

  const handleNegative = () => {
    setResponseFeedback((prevState) => ({ ...prevState, thumbdown: true }));
    setOpen(!open);
  };
  const handleSubmit = () => {
    postFeedback.mutate();
  };

  return (
    <>
      {/* {feedback === null && !isProvidedFeedback ? (
        <IconButton aria-label="ThumbUp" onClick={handlePositive}>
          <ThumbUpAltOutlinedIcon sx={{ height: "16px", width: "16px" }} />
        </IconButton>
      ) : (
        (feedback === true || isProvidedFeedback === "pos") && (
          <IconButton aria-label="ThumbUp">
            <ThumbUpIcon sx={{ height: "16px", width: "16px" }} />
          </IconButton>
        )
      )} */}

      {feedback === null && !isProvidedFeedback ? (
        <IconButton aria-label="ThumbDown" onClick={handleNegative}>
          <ThumbDownAltOutlinedIcon sx={{ height: "16px", width: "16px" }} />
        </IconButton>
      ) : (
        (feedback === false || isProvidedFeedback === "neg") && (
          <IconButton aria-label="ThumbUp">
            <ThumbDownIcon sx={{ height: "16px", width: "16px" }} />
          </IconButton>
        )
      )}

      <Dialog open={open} handler={handleOpen}>
        <DialogHeader className="text-lg border-b-2 flex justify-start items-center">
          <span className="p-2 bg-red-100 me-2 rounded-full">
            <ThumbDownAltOutlinedIcon className=" text-red-300 text-4xl" />
          </span>
          Provide additional feedback
        </DialogHeader>
        <DialogBody>
          <Textarea
            value={responseFeedback.description}
            onChange={(e) => handleChange(e)}
            className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
            variant="outlined"
            placeholder="What was the issue with the response? How could it be improved?"
            rows={6}
            labelProps={{
              className: "hidden",
            }}
          />
        </DialogBody>
        <DialogFooter>
          <div className="flex gap-2">
            <Button
              size="sm"
              color="gray"
              variant="text"
              className="rounded-md"
              onClick={handleOpen}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              className="rounded-md normal-case"
              onClick={handleSubmit}
            >
              Submit Feedback
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default ResponseFeedback;
