import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import apiClient from "../store/apiClient";

export function VerifyPage() {
  const [message, setMessage] = useState("Verifying...");
  const [mount, setMount] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const verify = useCallback(async () => {
    const paths = location.pathname.split("/");
    const userId = paths[2];
    const token = paths[3];
    apiClient
      .put(`/user/emailverify/${userId}/${token}`)
      .then((res) => {
        const { token } = res.data;
        localStorage.setItem("accessToken", token);
        navigate("/chat");
      })
      .catch((error) => {
        if (error.response.status === 500) {
          setMessage("Oops! Sever has one problem! Please try again!");
        } else {
          setMessage("Please! please try with correct link.");
        }
      });
  }, [location, navigate]);

  useEffect(() => {
    if (mount === true) {
      verify();
    }
  }, [verify, mount]);

  useEffect(() => {
    setMount(true);
  }, []);
  return (
    <Box sx={{ background: "white", width: "100%" }}>
      <Box
        sx={{
          margin: "auto",
          marginTop: "100px",
          minWidth: "400px",
          maxWidth: "600px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Link to="/">
          <Box
            component="img"
            src="/images/M__logo.png"
            sx={{ width: "100px" }}
          />
        </Link>
        <Box sx={{ fontSize: "30px", marginTop: "20px" }}>{message}</Box>
      </Box>
    </Box>
  );
}

export default VerifyPage;
