import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    TextField,
    Button,
    Select,
    MenuItem,
    Avatar, AlertColor
} from "@mui/material";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { handleUploadLogo, getBotDataMyc, updateMycBot} from "../../../store/bot/actions";
import {FC, useEffect, useState} from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CircularProgress from '@mui/material/CircularProgress';


const validationSchema = Yup.object({
    botName: Yup.string()
        .min(1, 'Must be at least 1 character')
        .max(20)
        .required('Required'),
    primaryDomain: Yup.string()
        .min(1, 'Must be a at least 1 character')
        .max(255)
        .required('Required'),
    greeting: Yup.string()
        .min(2, 'Must be at least 2 characters')
        .max(255)
        .required('Required'),
    tone: Yup.string()
        .required('Required'),
    additionalCertification: Yup.string()
        .max(100)
        .required('Required'),
    specialInstruction: Yup.string()
        .max(200)
        .required('Required'),
});
interface UpdateBotAdminProps {
}
const UpdateBotAdmin : FC<UpdateBotAdminProps> = () => {
    const { customBot } = useSelector(
        (state: any) => state.bot
    );
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState<any>('Upload Logo')
    const [previewAvatar, setPreviewAvatar] = useState<any>(
        customBot?.iconUrl ? customBot.iconUrl : '/images/seeker.png'
    );
    const navigate = useNavigate();
    const [loading, ] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const onUploadCallBack = ({
                                  status,
                                  returnData,
                              }: {
        status: AlertColor | undefined;
        returnData: any;
    }) => {
        if (status === 'success') {
            setIsUploading(false)
            formik.setFieldTouched('iconUrl', true);
            formik.setFieldValue('iconUrl', returnData.data.imageUrl);
            setFileName('Upload Successfully')

        } else {
            setIsUploading(false)
        }
    };
    useEffect(() => {
        dispatch(getBotDataMyc());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const imageSelect = (e: any) => {
        setIsUploading(true)
        const form = new FormData()
        form.append("file", e.currentTarget.files[0])
        dispatch(handleUploadLogo(form, onUploadCallBack));

        (e?.currentTarget?.files[0]) &&
        setPreviewAvatar(URL.createObjectURL(e.currentTarget.files[0]));
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: customBot?.id,
            botName: customBot?.botName || '',
            iconUrl: customBot?.iconUrl || '',
            primaryDomain: customBot?.primaryDomain || '',
            greeting: customBot?.greeting || '',
            tone: customBot?.tone || '',
            additionalCertification: customBot?.additionalCertification || '',
            specialInstruction: customBot?.specialInstruction || '',
        },
        validationSchema,
        onSubmit: async (values: any, helpers: any): Promise<void> => {
            dispatch(updateMycBot(values, navigate, helpers));
        },
    });
    return (
        <Box
            sx={{
                maxHeight: 'calc(100vh - 110px)',
                marginTop: '5px',
                padding: {xs: '12px 20px', lg: '12px 100px'},
                overflowY: 'scroll',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: { lg: 'flex', xs: 'block' },
                        mt: '20px',
                        mb: '10px',
                    }}
                >
                    <Avatar
                        src={customBot ? customBot.iconUrl : previewAvatar}
                        sx={{
                            height: 118,
                            width: 118,
                            borderRadius : '50%',
                            margin: { lg: '0px 20px 0px 0px', xs: '0px auto 20px auto' },
                            '& .MuiAvatar-img': {
                                // objectFit: 'contain',
                            },
                        }}
                    >
                        <AccountCircleRoundedIcon fontSize="large" />
                    </Avatar>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            size="large"
                            color="primary"
                            variant="outlined"
                            component="label"
                            sx={{ borderRadius: '9px' }}
                        >
                            {isUploading ? <CircularProgress /> : fileName }
                            <input type="file" name="avatar" hidden onChange={imageSelect} />
                        </Button>
                    </Box>
                </Box>

                <Grid container spacing={4}>
                    <Grid item xs={12} lg={6}>
                        <FormControl fullWidth sx={{mt: 1, flexGrow: 1, mr: 10}}>
                            <FormLabel sx={{marginBottom: '8px'}}>Bot Name</FormLabel>
                            <TextField
                                name="botName"
                                type="text"
                                fullWidth
                                error={!!(formik.touched.botName && formik.errors.botName)}
                                helperText={
                                    (formik.touched.botName && formik.errors.botName) as string
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.botName}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <FormControl fullWidth sx={{mt: 1, flexGrow: 1, mr: 10}}>
                            <FormLabel sx={{marginBottom: '8px'}}>Tone/Personality</FormLabel>
                            <Select
                                variant="outlined"
                                id="tone"
                                name="tone"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.tone}
                                error={Boolean(formik.touched.tone && formik.errors.tone)}
                            >
                                <MenuItem value={"friendly"}>Friendly</MenuItem>
                                <MenuItem value={"formal"}>Formal</MenuItem>
                                <MenuItem value={"casual"}>Casual</MenuItem>
                                <MenuItem value={"witty"}>Witty</MenuItem>
                                <MenuItem value={"compassionate"}>Compassionate</MenuItem>
                            </Select>

                        </FormControl>
                    </Grid>
                </Grid>
                <FormControl fullWidth sx={{mt: 2, flexGrow: 1, mr: 10}}>
                    <FormLabel sx={{marginBottom: '8px'}}>Primary Domain/Expertise</FormLabel>
                    <TextField
                        name="primaryDomain"
                        type="text"
                        error={
                            !!(formik.touched.primaryDomain && formik.errors.primaryDomain)
                        }
                        helperText={
                            (formik.touched.primaryDomain && formik.errors.primaryDomain)  as string
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.primaryDomain}
                    />
                </FormControl>
                {/*<FormControl fullWidth sx={{mt: 2, flexGrow: 1, mr: 10}}>*/}
                {/*    <FormLabel sx={{marginBottom: '8px'}}>Greeting</FormLabel>*/}
                {/*    <TextField*/}
                {/*        fullWidth*/}
                {/*        name="greeting"*/}
                {/*        type="text"*/}
                {/*        autoComplete="aa"*/}
                {/*        error={*/}
                {/*            !!(formik.touched.greeting && formik.errors.greeting)*/}
                {/*        }*/}
                {/*        helperText={*/}
                {/*            formik.touched.greeting && formik.errors.greeting*/}
                {/*        }*/}
                {/*        onBlur={formik.handleBlur}*/}
                {/*        onChange={formik.handleChange}*/}
                {/*        value={formik.values.greeting}*/}
                {/*    />*/}
                {/*</FormControl>*/}
                <FormControl fullWidth sx={{mt: 2, flexGrow: 1, mr: 10}}>
                    <FormLabel>Additional certifications</FormLabel>
                    <TextField
                        fullWidth
                        name="additionalCertification"
                        type="text"
                        error={
                            !!(formik.touched.additionalCertification && formik.errors.additionalCertification)
                        }
                        helperText={
                            (formik.touched.additionalCertification && formik.errors.additionalCertification) as string
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.additionalCertification}
                    />
                </FormControl>
                <FormControl fullWidth sx={{mt: 2, flexGrow: 1, mr: 10}}>
                    <FormLabel sx={{marginBottom: '8px'}}>Special Instructions/Behaviors</FormLabel>
                    <TextField
                        fullWidth
                        name="specialInstruction"
                        type="text"
                        error={
                            !!(formik.touched.specialInstruction && formik.errors.specialInstruction)
                        }
                        helperText={
                            (formik.touched.specialInstruction && formik.errors.specialInstruction) as string
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.specialInstruction}
                    />
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                    className={'w-full'}
                >
                    <LoadingButton
                        loading={loading}
                        size="large"
                        sx={{
                            mt: 2,
                            borderRadius: '86px',
                            height: '60.65px',
                            mb: '20px',
                            width: '205px',
                        }}
                        type="submit"
                        variant="contained"
                    >
                        Save
                    </LoadingButton>
                </Box>
            </form>
        </Box>
    )
}

export default UpdateBotAdmin;