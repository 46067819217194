import {
  Tab,
  TabPanel,
  Tabs,
  TabsHeader,
  TabsBody,
} from "@material-tailwind/react";
import { PersonalityGreetingEditor } from ".";
import UpdateBotAdmin from "../update_bot_admin";
import React, { useEffect } from "react";
import { getBotDataMyc } from "../../../../store/bot/actions";
import { useDispatch, useSelector } from "react-redux";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import Integrations from "../../../Embedding/Integrations";
import {
  ChatBubbleLeftRightIcon,
  CodeBracketSquareIcon,
} from "@heroicons/react/20/solid";

export function PersonalityAdmin() {
  const { customBot } = useSelector((state: any) => state.bot);
  const dispatch = useDispatch();

  useEffect(() => {
    !customBot && dispatch(getBotDataMyc());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customBot]);

  const data = [
    {
      label: "Greeting Message",
      value: "greeting-message-editor",
      icon: ChatBubbleLeftRightIcon,
      desc: <PersonalityGreetingEditor />,
    },
    {
      label: "Embedding",
      value: "dashboard",
      icon: CodeBracketSquareIcon,
      desc: <Integrations />,
    },
    {
      label: "Settings",
      value: "settings",
      icon: Cog6ToothIcon,
      desc: <UpdateBotAdmin />,
    },
  ];
  window.document.title = "Mushgpt | Admin | OtherAdmin";
  return (
    <Tabs value="greeting-message-editor" orientation="horizontal">
      <TabsHeader className="justify-evenly">
        {data.map(({ label, value, icon }) => (
          <Tab key={value} value={value} className="max-w-[200px]">
            <div className="flex items-center gap-2">
              {React.createElement(icon, { className: "w-5 h-5" })}
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value} className="py-2">
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default PersonalityAdmin;
