import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Card,
  CardHeader,
  FormControl,
  FormLabel,
  InputAdornment,
  CardContent,
  Stack,
  TextField,
  FormHelperText,
  Button,
  Grid,
  IconButton,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidPhoneNumber } from "libphonenumber-js";

// ** Import Actions
import { register } from "../../store/auth/actions";

// ** Import type
import type { FC } from "react";
import GoogleSignIn from "./GoogleSignIn";

interface Values {
  firstName: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordConfirm: string;
  policy: boolean;
  submit: null;
}

const initialValues: Values = {
  firstName: "",
  email: "",
  phoneNumber: "",
  password: "",
  passwordConfirm: "",
  policy: false,
  submit: null,
};

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .max(255)
    .required("Required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string()
    .min(7, "Must be at least 7 characters")
    .max(255)
    .required("Required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
  policy: Yup.boolean().isTrue("You must accept the TOS").required(),
});

const SignUp: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>("");
  const [showPasswordConfirm, setShowPasswordConfirm] =
    useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);

  const handleMouseDownPasswordConfirm = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any, helpers: any): Promise<void> => {
      if (!phoneError) {
        dispatch(register(values, navigate, helpers));
      }
    },
  });

  const onPhoneNumberChange = (c: any) => {
    formik.setFieldTouched("phoneNumber", true);
    formik.setFieldValue("phoneNumber", c);
    if (!isValidPhoneNumber(c)) {
      setPhoneError("Invalid Phone Number");
    } else {
      setPhoneError("");
    }
  };

  return (
    <Box
      // container
      sx={{
        height: "100vh",
        minHeight: "1200px",
        backgroundImage: 'url("/images/mgpt-bg-compressed.webp")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flow-root",
      }}
      // alignItems="center"
      // justifyContent="center"
      // alignContent="center"
    >
      <Box
        // item
        sx={{ position: "relative" }}
      >
        <Box
          sx={{ position: "absolute", top: "-74px", left: "calc(50% - 56px)" }}
        >
          <Link to="/">
            <img
              src="/images/M__logo.png"
              alt="MushGPT"
              className="max-w-[112px] max-h-[112px]"
            />
          </Link>
        </Box>
        <Card
          elevation={16}
          sx={{
            // width: '29vw',
            width: {
              xs: "90vw",
              sm: "490px",
            },
            // minWidth: '490px',
            minHeight: "538px",
            margin: "auto",
            marginTop: "100px",
            borderRadius: "30px",
            padding: {
              sm: "33px 42px",
              xs: "33px 15px",
            },
          }}
        >
          <CardHeader sx={{ pb: 0 }} />
          <CardContent>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: "primary.main",
                  fontFamily: "Poppins !important",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "1.875rem !important",
                  lineHeight: 2.07,
                }}
              >
                Welcome Seeker
              </Typography>
            </Box>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel sx={{ marginBottom: "8px" }}>First Name</FormLabel>
                  <TextField
                    error={
                      !!(formik.touched.firstName && formik.errors.firstName)
                    }
                    fullWidth
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    name="firstName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.firstName}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ marginBottom: "8px" }}>
                    Email address
                  </FormLabel>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ marginBottom: "8px" }}>
                    Phone Number
                  </FormLabel>
                  <MuiPhoneNumber
                    defaultCountry="us"
                    variant="outlined"
                    error={!!(formik.touched.phoneNumber && phoneError)}
                    fullWidth
                    helperText={formik.touched.phoneNumber && phoneError}
                    name="phoneNumber"
                    onBlur={formik.handleBlur}
                    onChange={onPhoneNumberChange}
                    type="text"
                    value={formik.values.phoneNumber}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ marginBottom: "8px" }}>Password</FormLabel>
                  <OutlinedInput
                    error={
                      !!(formik.touched.password && formik.errors.password)
                    }
                    fullWidth
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={formik.values.password}
                  />
                  {formik.touched.password && (
                    <Box sx={{ mt: 0 }}>
                      <FormHelperText error>
                        {formik.touched.password && formik.errors.password}
                      </FormHelperText>
                    </Box>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel sx={{ marginBottom: "8px" }}>
                    Confirm Password
                  </FormLabel>
                  <OutlinedInput
                    error={
                      !!(
                        formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm
                      )
                    }
                    fullWidth
                    name="passwordConfirm"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPasswordConfirm ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password confirm visibility"
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPasswordConfirm}
                        >
                          {showPasswordConfirm ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={formik.values.passwordConfirm}
                  />
                  {formik.touched.passwordConfirm && (
                    <Box sx={{ mt: 0 }}>
                      <FormHelperText error>
                        {formik.touched.passwordConfirm &&
                          formik.errors.passwordConfirm}
                      </FormHelperText>
                    </Box>
                  )}
                </FormControl>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.policy}
                          name="policy"
                          onChange={formik.handleChange}
                        />
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          color: "#8F92A1",
                          fontWeight: 400,
                          fontSize: "0.875rem",
                        },
                      }}
                      label="By signing up you accept the Term of service"
                    />
                    {formik.touched.policy && (
                      <Box sx={{ mt: 0 }}>
                        <FormHelperText error>
                          {formik.touched.policy && formik.errors.policy}
                        </FormHelperText>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Stack>
              {formik.errors.submit && (
                <FormHelperText error sx={{ mt: 3 }}>
                  {formik.errors.submit as string}
                </FormHelperText>
              )}
              <Button
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                sx={{
                  mt: 2,
                  borderRadius: "86px",
                  height: "60.65px",
                  mb: "20px",
                }}
                type="submit"
                variant="contained"
              >
                Sign Up
              </Button>
            </form>
            <GoogleSignIn />
            <Typography
              variant="subtitle2"
              sx={{ color: "#929292", fontSize: "16px !important" }}
            >
              Already have an account?&nbsp;
              <Link className="signup" to="/signin">
                Sign In
              </Link>
            </Typography>
          </CardContent>
          <Box
            sx={{
              position: "absolute",
              bottom: "-113px",
              left: "calc(50% - 85.5px)",
            }}
          >
            <img src="/images/image 7.png" alt="MushGPT" />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default SignUp;
