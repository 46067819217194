import * as actionTypes from "../constants";

const initialState: {
  dataFlow: string;
} = {
  dataFlow: "main",
};

const reducer = (
  prevState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    // ** When user press enterKey or send message button
    case actionTypes.CHANGE_DATA_FLOW:
      return {
        ...prevState,
        dataFlow: action.payload,
      };

      return {
        ...prevState,
        llmModel: action.payload,
      };
    default:
      return prevState;
  }
};

export default reducer;
